import axios from 'axios';

const apiUrl = process.env.REACT_APP_API_URL;

export const customSignupandLogin = async (phone) => {
  try {
    const response = await axios.get(`${apiUrl}/auth/get-otp/${phone}`);
    const data = response.status;
    return data;
  } catch (error) {
    console.log('customSignupandLogin', error);
    return error;
  }
};

export const setcustomJWT = async (phone, otp) => {
  try {
    const response = await axios.get(`${apiUrl}/auth/get-token/${phone}/${otp}`);
    const data = response.data;
    return data;
  } catch (error) {
    console.log('setcustomJWT', error);
    return error;
  }
};

export const checkUserAccount = async (token) => {
  try {
    const response = await axios.get(`${apiUrl}/auth/info-check`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    const data = response.data;
    return data;
  } catch (error) {
    console.log('checkUserAccount', error);
    return error;
  }
};

export const signUpUser = async (token, inviteRFCode) => {
  try {
    const response = await axios.post(
      `${apiUrl}/auth/signup/${inviteRFCode ? `${inviteRFCode}` : 'user'}`,
      null,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const getOTP = async (token) => {
  try {
    await axios.get(`${apiUrl}/tx/get-otp`, {
      headers: { Authorization: `Bearer ${token}` },
    });
  } catch (error) {
    return error;
  }
};

export const getAgentOTP = async (token, phoneNumber) => {
  try {
    await axios.get(`${apiUrl}/agent/tx/get-otp/${phoneNumber}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
  } catch (error) {
    return error;
  }
};

export const updateAgentStatus = async (token, status) => {
  try {
    const response = await axios.put(
      `${apiUrl}/agent/update-availability/${status}`,
      {},
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const getUserProfile = async (token) => {
  try {
    const response = await axios.get(`${apiUrl}/auth/get-account`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    const data = response.data;
    return data;
  } catch (error) {
    console.log('getUserProfile', error);
    return error;
  }
};

export const updateUserProfile = async (userInfo, token) => {
  try {
    const response = await axios.post(`${apiUrl}/auth/personal-info-edit-request`, userInfo, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response;
  } catch (error) {
    console.log('updateUserProfile', error);
    return error;
  }
};

export const getUserBalance = async (token) => {
  try {
    const response = await axios.get(`${apiUrl}/tx/get-balance`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response;
  } catch (error) {
    console.log('getUserBalance', error);
    return error;
  }
};

export const setUserDevice = async (code, token) => {
  try {
    const response = await axios.post(
      `${apiUrl}/auth/save-device`,
      { code: code },
      { headers: { Authorization: `Bearer ${token}` } }
    );
    const data = response.data;
    return data;
  } catch (error) {
    console.log('setUserDevice', error);
    return error;
  }
};

export const getUserDevice = async (token) => {
  try {
    const response = await axios.get(`${apiUrl}/auth/get-device`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    const data = response.data;
    return data;
  } catch (error) {
    console.log('getUserDevice', error);
    return error;
  }
};

export const addEmail = async (email, token, subscribe) => {
  try {
    const response = await axios.post(`${apiUrl}/account/add-email/${email}/${subscribe}`, null, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response;
  } catch (error) {
    console.log('addEmail', error);
    return error;
  }
};

export const verificationEmail = async (userToken, emailToken) => {
  try {
    const response = await axios.get(`${apiUrl}/account/verify-email/${emailToken}`, {
      headers: { Authorization: `Bearer ${userToken}` },
    });
    return response;
  } catch (error) {
    console.log('verificationEmail', error);
    return error;
  }
};

export const updateKYC = async (step, stepData, token) => {
  try {
    const response = await axios.put(`${apiUrl}/auth/update-kyc/${step}`, stepData, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response;
  } catch (error) {
    console.log('updateKYC', error);
    return error;
  }
};

export const submitKYC = async (data, token) => {
  try {
    const response = await axios.post(`${apiUrl}/auth/submit-kyc`, data, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response;
  } catch (error) {
    console.log('submitKYC', error);
    return error;
  }
};
export const  getTXlist = async (token,period = 3) => {
  try {
    const response = await axios.get(
      `${apiUrl}/tx/activity?period=${period}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response;
  } catch (error) {
    console.log('getTXlist', error);
    return error;
  }
};
// export const  getTXlist = async (token, search, period = 3) => {
//   try {
//     const response = await axios.get(
//       `${apiUrl}/tx/activity${search ? `?search=${search}&` : '?'}period=${period}`,
//       {
//         headers: { Authorization: `Bearer ${token}` },
//       }
//     );
//     return response;
//   } catch (error) {
//     console.log('getTXlist', error);
//     return error;
//   }
// };

export const getTXlast = async (token) => {
  try {
    const response = await axios.get(`${apiUrl}/tx/latest-tx`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response;
  } catch (error) {
    console.log('getTXlast', error);
    return error;
  }
};

export const swapCurrency = async (swap, userToken, deviceToken) => {
  try {
    const response = await axios.post(`${apiUrl}/tx/swap`, swap, {
      headers: { Authorization: `Bearer ${userToken}`, device: deviceToken },
    });
    return response;
  } catch (error) {
    console.log('swapCurrency', error);
    return error;
  }
};

export const sendCurrency = async (data, userToken, deviceToken, currency) => {
  try {
    const response = await axios.post(`${apiUrl}/tx/${currency}/transfer`, data, {
      headers: { Authorization: `Bearer ${userToken}`, device: deviceToken },
    });
    return response;
  } catch (error) {
    console.log('sendCurrency', error);
    return error;
  }
};

export const withdrawCurrency = async (data, userToken, deviceToken, currency) => {
  try {
    const response = await axios.post(`${apiUrl}/tx/${currency}/withdraw`, data, {
      headers: { Authorization: `Bearer ${userToken}`, device: deviceToken },
    });
    return response;
  } catch (error) {
    console.log('sendCurrency', error);
    return error;
  }
};

export const getAgentsList = async (token, online) => {
  try {
    const response = await axios.get(`${apiUrl}/agent/get-agents${online ? '?online=true' : ''}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response;
  } catch (error) {
    return error;
  }
};

export const getAgentsSearch = async (token, searchValue, online = false, page = 1, limit = 5) => {
  try {
    const response = await axios.get(
      `${apiUrl}/agent/search/${searchValue}?online=${online}?page=${page}&limit=${limit}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const getUserBanner = async (token) => {
  try {
    const response = await axios.get(`${apiUrl}/users/market-banner`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response;
  } catch (error) {
    console.log('getUserBanner', error);
    return error;
  }
};

export const getContacts = async (token, page = 1, limit = 5, filter = 'all') => {
  try {
    const response = await axios.get(
      `${apiUrl}/contact/get-contacts/${filter}?page=${page}&limit=${limit}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const updateFavouriteStatus = async (token, accountId, action) => {
  try {
    const response = await axios.put(
      `${apiUrl}/contact/update-favourite/${accountId}/${action}`,
      null,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    const data = response.data;
    return data;
  } catch (error) {
    return error;
  }
};

export const getUsers = async (token, page, limit, value) => {
  try {
    const response = await axios.get(
      `${apiUrl}/users/search/users/${value}?page=${page}&limit=${limit}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const getContactsSearch = async (token, page, limit, filter, value) => {
  try {
    const response = await axios.get(
      `${apiUrl}/contact/search/${filter}/${value}?page=${page}&limit=${limit}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const getTransactionDetails = async (token, transactionId) => {
  try {
    const response = await axios.get(`${apiUrl}/tx/transaction/${transactionId}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response;
  } catch (error) {
    return error;
  }
};

export const updateNotification = async (token, notificationID, action) => {
  try {
    const response = await axios.put(
      `${apiUrl}/notifications/update-read/${notificationID}/${action}`,
      null,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const deleteNotification = async (token, notificationID) => {
  try {
    const response = await axios.put(`${apiUrl}/notifications/delete/${notificationID}`, null, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response;
  } catch (error) {
    return error;
  }
};

export const getAvatar = async (url) => {
  try {
    if (url) {
      const response = await axios.get(url, { responseType: 'blob' });
      const blob = new Blob([response.data], { type: response.headers['content-type'] });
      const imageUrl = URL.createObjectURL(blob);
      return imageUrl;
    }
  } catch (error) {
    console.error('Error fetching image:', error);
  }
};

export const updateAvatar = async (data, userToken) => {
  try {
    const response = await axios.put(`${apiUrl}/account/edit-avatar`, data, {
      headers: { Authorization: `Bearer ${userToken}` },
    });
    return response;
  } catch (error) {
    console.log('sendCurrency', error);
    return error;
  }
};

export const deleteAvatar = async (token) => {
  try {
    const response = await axios.delete(`${apiUrl}/account/delete-avatar`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response;
  } catch (error) {
    return error;
  }
};

export const getNotifications = async (token) => {
  try {
    const response = await axios.get(`${apiUrl}/notifications/get-notifications`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response;
  } catch (error) {
    return error;
  }
};

export const activateSSP = async (token, value) => {
  try {
    const response = await axios.post(`${apiUrl}/account/activate-ssp/${value}`, null, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response;
  } catch (error) {
    return error;
  }
};

export const getSettings = async (token) => {
  try {
    const response = await axios.get(`${apiUrl}/account/get-settings`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response;
  } catch (error) {
    return error;
  }
};

export const logOutAllDevices = async (userToken) => {
  try {
    const response = await axios.put(`${apiUrl}/account/logout-devices`, null, {
      headers: { Authorization: `Bearer ${userToken}` },
    });
    return response;
  } catch (error) {
    console.log('logOutAllDevices', error);
    return error;
  }
};

// AGENTS ENDPOINTS
export const deleteAccount = async (token, reason) => {
  try {
    const response = await axios.delete(`${apiUrl}/auth/delete-account?reason=${reason}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response;
  } catch (error) {
    console.log('deleteUser', error);
    return error;
  }
};

export const createRemit = async (token, deviceJwt, data) => {
  try {
    const response = await axios.post(`${apiUrl}/agent/request-id`, data, {
      headers: { Authorization: `Bearer ${token}`, device: deviceJwt },
    });
    return response;
  } catch (error) {
    return error;
  }
};

export const submitSender = async (token, deviceJwt, remitID, data) => {
  try {
    const response = await axios.post(`${apiUrl}/agent/tx/submit-sender/${remitID}`, data, {
      headers: { Authorization: `Bearer ${token}`, device: deviceJwt },
    });
    return response;
  } catch (error) {
    return error;
  }
};

export const submitReceiver = async (token, deviceJwt, remitID, data) => {
  try {
    const response = await axios.post(`${apiUrl}/agent/tx/submit-receiver/${remitID}`, data, {
      headers: { Authorization: `Bearer ${token}`, device: deviceJwt },
    });
    return response;
  } catch (error) {
    return error;
  }
};

export const completeFlowRemit = async (token, deviceJwt, txID, data) => {
  try {
    const response = await axios.post(`${apiUrl}/agent/tx/complete-remit/${txID}`, data, {
      headers: { Authorization: `Bearer ${token}`, device: deviceJwt },
    });
    return response;
  } catch (error) {
    return error;
  }
};

export const assignAgent = async (token, deviceJwt, remitID, agent) => {
  try {
    const response = await axios.put(
      `${apiUrl}/agent/tx/assign/${remitID}/${agent}`,
      {},
      {
        headers: { Authorization: `Bearer ${token}`, device: deviceJwt },
      }
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const acceptRemit = async (txId, token, deviceJwt) => {
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
    device: deviceJwt,
    // Add any other custom headers if needed
  };
  try {
    const response = await fetch(`${apiUrl}/agent/tx/accept/${txId}`, {
      method: 'PUT',
      headers: headers,
    });

    // Get the response status
    const data = response.status;
    return data;
  } catch (error) {
    return error;
  }
};

export const declineRemit = async (txId, token, deviceJwt, reason) => {
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
    device: deviceJwt,
    // Add any other custom headers if needed
  };
  try {
    const response = await fetch(`${apiUrl}/agent/tx/decline/${txId}?reason=${encodeURIComponent(reason)}`, {
      method: 'PUT',
      headers: headers,
    });
    // Get the response status
    return response;
  } catch (error) {
    return error;
  }
};

export const completeRemit = async (txId, token, deviceJwt) => {
  try {
    const response = await axios.put(
      `${apiUrl}/agent/tx/complete/${txId}`,
      {},
      {
        headers: { Authorization: `Bearer ${token}`, device: deviceJwt },
      }
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const addRemitToPool = async (txId, token, deviceJwt) => {
  try {
    const response = await axios.put(
      `${apiUrl}/agent/tx/assign-pool/${txId}/`,
      {},
      {
        headers: { Authorization: `Bearer ${token}`, device: deviceJwt },
      }
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const cancelRemit = async (txId, token, deviceJwt, code, reason) => {
  try {
    const response = await axios.post(
      `${apiUrl}/agent/tx/cancel/${txId}`,
      { code: code, reason: reason },
      {
        headers: { Authorization: `Bearer ${token}`, device: deviceJwt },
      }
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const getContactSend = async (token, value) => {
  try {
    const response = await axios.get(`${apiUrl}/users/search/users/${value}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    const data = response.data;
    return data;
  } catch (error) {
    return error;
  }
};

export const getAgentsCountries = async (token) => {
  try {
    const response = await axios.get(`${apiUrl}/agent/get-locations`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response;
  } catch (error) {
    return error;
  }
};

export const getUserData = async (token, userID) => {
  try {
    const response = await axios.get(`${apiUrl}/users/userdata/${userID}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response;
  } catch (error) {
    return error;
  }
};
