import React from 'react';

const ShortenedAddress = ({ address, maxLength = 10 }) => {
  if (address.length <= maxLength) {
    return <span>{address}</span>;
  }

  const start = address.substr(0, maxLength / 2);
  const end = address.substr(address.length - maxLength / 2);

  return (
    <span>
      {start}...{end}
    </span>
  );
};

export default ShortenedAddress;
