import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ArrowBack from '../../../components/ArrowBack';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectFirebaseJWT,
  selectJwtToken,
  selectPhoneNumber,
} from '../../../redux/features/user/userSlice';
import {
  resetCurrentRemit,
  selectRemitDetails,
  updatecurrentOrder,
} from '../../../redux/features/transaction/remittanceSlice';
import TFA from '../../../components/TFA';
import Success from '../../../components/Success';
import sendSuccessVideo from '../../../assets/anims/videos/fullscreen/send_success_ff.mp4';
import { useNavigate } from 'react-router-dom';
import SendStepDestination from './SendStep/SendStepDestination';
import SendStepAgents from './SendStep/SendStepAgents';
import { toast } from 'react-toastify';
import { completeFlowRemit } from '../../../api';
import findOrderById from '../../../utils/findOrderById';
import { selectOrders } from '../../../redux/features/sockets/socketslice';
import { Button } from '@mui/material';
import useHideBtn from '../../../utils/useHideBtn';

const RemitSend = ({ steps, changeMainStep, changeStep }) => {
  const userPhone = useSelector(selectPhoneNumber);
  const { pool, incoming, outgoing } = useSelector(selectOrders);
  const userToken = useSelector(selectFirebaseJWT);
  const userDeviceJwt = useSelector(selectJwtToken);
  const remmitDetails = useSelector(selectRemitDetails);
  const [agent, setAgent] = useState('agent');
  const [error, setError] = useState(false);
  const [createdOrder, setCreatedOrder] = useState(null);
  const [localLoading, setLocalLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useHideBtn(steps, 3);

  const submitRemmitTX = async (otpCode) => {
    try {
      setLocalLoading(true);

      const remmitOBJ = {
        amount: remmitDetails.sendValue,
        feeCurrency: remmitDetails.currency,
        verificationType: remmitDetails.verificationCode,
        comment: remmitDetails.comment,
        code: otpCode,
      };
      console.log(remmitOBJ);
      const response = await completeFlowRemit(
        userToken,
        userDeviceJwt,
        remmitDetails.remitID,
        remmitOBJ
      );

      if (response.status === 200) {
        changeStep('sendSteps', 1);
      } else {
        throw new Error(response.message);
      }
    } catch (error) {
      toast.error(`Something went wrong...${error?.message || 'Oops!'}`);
      console.log(error);
      setError(true);
    } finally {
      setLocalLoading(false);
    }
  };

  const changeTFAstep = () => changeStep('sendSteps', 1);

  const updareChatOrder = () => {
    !localLoading && setLocalLoading(true);
    const currentOrder = findOrderById({ pool, incoming, outgoing }, remmitDetails.remitID);

    if (currentOrder) {
      setCreatedOrder(currentOrder);
      dispatch(updatecurrentOrder(currentOrder));
      currentOrder && setLocalLoading(false);
    } else {
      setTimeout(() => {
        updareChatOrder();
      }, 2000);
    }
  };

  useEffect(() => {
    if (!createdOrder && steps === 3) updareChatOrder();

    return () => {
      steps === 3 && dispatch(resetCurrentRemit());
    };
  }, [steps, pool, incoming, outgoing]);

  return (
    <div className='send-step'>
      {steps === 0 && (
        <>
          <ArrowBack handle={() => changeMainStep(-1)} />

          <TFA
            loading={localLoading}
            number={userPhone}
            changeStep={changeTFAstep}
            handle={submitRemmitTX}
            resendAgentFn={true}
            setError={setError}
            error={error}
            confirmBtnText='Confirm send'
            resendByOTP={true}
            closeFlowFn={true}
          />
        </>
      )}

      {steps === 1 && <SendStepDestination changeStep={changeStep} />}

      {steps === 2 && <SendStepAgents changeStep={changeStep} setAgent={setAgent} />}

      {steps === 3 && (
        <Success
          video={sendSuccessVideo}
          title={
            agent !== 'agent'
              ? `Your send request has been sent to an ${agent?.name}`
              : 'Your send request has been added to pool'
          }
          subtitle={
            'You can find the transaction in <a class="el-button-link" href="/agent/outgoing-orders"><span>Outgoing orders</span></a>'
          }
          buttons={
            <>
              <Button className='el-button orange' onClick={() => navigate('/agent/chat')}>
                <span>View order details</span>
              </Button>
            </>
          }
        />
      )}
    </div>
  );
};

RemitSend.propTypes = {
  changeStep: PropTypes.func.isRequired,
};

export default RemitSend;
