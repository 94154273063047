import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import img from '../../assets/img/p-dashboard/icon-reward-no-bg.png';
import PropTypes from 'prop-types';
import Popup from '../Popup';
import { Button } from '@mui/material';
import ReactPlayer from 'react-player';
import rewardsbanner from '../../assets/anims/videos/popups/rewards_ff.mp4';

const Estimate = ({ type = 'large', count, text, registration }) => {
  const [popup, setPopup] = useState(false);
  const togglePopup = () => setPopup(!popup);

  return (
    <>
      {type === 'large' && (
        <div className='flow-estimate'>
          <div className='flow-estimate-img'>
            <img src={img} alt='estimate image' />
          </div>

          <div className='el-text-s semi flow-estimate-text'>{count ? count : '5'} PESA</div>
          <div className='flow-estimate-icon' onClick={togglePopup}>
            <span className='el-text-s'> {text ? text : 'Your estimated reward'}</span>
            <svg
              width='16'
              height='16'
              viewBox='0 0 16 16'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M15 8C15 11.866 11.866 15 8 15C4.13401 15 1 11.866 1 8C1 4.13401 4.13401 0.999999 8 0.999999C11.866 1 15 4.13401 15 8Z'
                stroke='#6D757D'
                strokeWidth='2'
              />
              <path
                d='M9 11C9 11.5523 8.55228 12 8 12C7.44772 12 7 11.5523 7 11C7 10.4477 7.44772 10 8 10C8.55228 10 9 10.4477 9 11Z'
                fill='#6D757D'
              />
              <path
                d='M6 6.5V6.5C6 5.67157 6.67157 5 7.5 5H8.76393C9.44659 5 10 5.55341 10 6.23607V6.23607C10 6.70426 9.73548 7.13226 9.31672 7.34164L8.44721 7.77639C8.17313 7.91343 8 8.19357 8 8.5V8.5'
                stroke='#6D757D'
                strokeWidth='1.75'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
            </svg>
          </div>
        </div>
      )}

      {type === 'small' && (
        <div className='flow-estimate small'>
          <div className='flow-estimate-cont'>
            <div className='flow-estimate-img'>
              <img src={img} alt='estimate image' />
            </div>

            <div className='el-text-s semi flow-estimate-text'>
              {count ? count : '5'} PESA
              <span className='subtitle'> {text ? text : 'Your estimated reward'}</span>
            </div>
          </div>

          <div className='flow-estimate-icon' onClick={togglePopup}>
            <svg
              width='16'
              height='16'
              viewBox='0 0 16 16'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M15 8C15 11.866 11.866 15 8 15C4.13401 15 1 11.866 1 8C1 4.13401 4.13401 0.999999 8 0.999999C11.866 1 15 4.13401 15 8Z'
                stroke='#6D757D'
                strokeWidth='2'
              />
              <path
                d='M9 11C9 11.5523 8.55228 12 8 12C7.44772 12 7 11.5523 7 11C7 10.4477 7.44772 10 8 10C8.55228 10 9 10.4477 9 11Z'
                fill='#6D757D'
              />
              <path
                d='M6 6.5V6.5C6 5.67157 6.67157 5 7.5 5H8.76393C9.44659 5 10 5.55341 10 6.23607V6.23607C10 6.70426 9.73548 7.13226 9.31672 7.34164L8.44721 7.77639C8.17313 7.91343 8 8.19357 8 8.5V8.5'
                stroke='#6D757D'
                strokeWidth='1.75'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
            </svg>
          </div>
        </div>
      )}

      <Popup open={popup} togglePopUp={togglePopup}>
        <div className='modal-content'>
          <div className='modal-img'>
            <ReactPlayer
              className='player'
              controls={false}
              playing={true}
              loop={true}
              muted={true}
              playsinline={true}
              url={rewardsbanner}
              width='100%'
              height='100%'
            />
          </div>

          <div className='modal-desc'>
            <div className='modal-desc-title'>
              {registration
                ? 'Register new users and receive bonus to your balance!'
                : 'Rewards for you!'}
            </div>

            <div className='el-text-m modal-desc-text'>
              {registration ? (
                <>
                  {`
                  We pay you ${count} PESA each time you register a new user and they make 100 USD of transactions.
                  Your referral bonus will be added to your PESA balance after the transaction is processed.
                  You can also find it in your Activity history.
                  `}
                  <Link target='_blank' to={'/referral'} className='el-button-link'>
                    More details here
                  </Link>
                </>
              ) : (
                <>
                  {`
                  You will receive up to ${count} PESA for this transaction. 
                  We reward you each time you use our platform to send money.
                  Your reward will be added to your PESA balance after the transaction is processed.
                  You can find it in the transaction details in your Activity history.
                  `}
                  <Link target='_blank' to={'/rewards'} className='el-button-link'>
                    More details here
                  </Link>
                </>
              )}
            </div>
          </div>

          <div className='modal-btns'>
            <Button
              className='el-button orange el-button-continue'
              variant='contained'
              onClick={togglePopup}
            >
              Ok, got it
            </Button>
          </div>
        </div>
      </Popup>
    </>
  );
};

Estimate.propTypes = {
  type: PropTypes.string,
  count: PropTypes.string,
  text: PropTypes.string,
  registration: PropTypes.bool,
};

export default Estimate;
