import React from 'react';
import PropTypes from 'prop-types';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';

const DOBPicker = ({ dob, setDoB, expDate = false}) => {
  const DOB = dob && dayjs(new Date(parseInt(dob)));

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        className='el-input-date'
        label={expDate ? 'ID expiration date' : 'Date of birth'}
        maxDate={expDate ? '' : dayjs().endOf('year').subtract(18, 'years')}
        minDate={expDate ? dayjs() : dayjs().subtract(90, 'years')}
        format='DD.MM.YYYY'
        disableFuture={expDate ? false : true}
        openTo='year'
        onChange={(e) => setDoB(e)}
        value={null || DOB}
      />
    </LocalizationProvider>
  );
};

DOBPicker.propTypes = {
  dob: PropTypes.string,
  setDoB: PropTypes.func,
};

export default DOBPicker;
