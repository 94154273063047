import React, { useContext, useEffect, useRef, useState } from 'react';
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom';
import logo from '../../assets/img/logo-n.svg';
import Button from '@mui/material/Button';
import Slide from '@mui/material/Slide';
import google from '../../assets/img/p-home/google.png';
import apple from '../../assets/img/p-home/apple.png';
import Icon from '..//Icon';
import PropTypes from 'prop-types';
import { Container } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectFirebaseJWT,
  setTransferData,
  selectAvatar,
  selectTransferData,
  selectAccount,
} from '../../redux/features/user/userSlice';
import Popup from '..//Popup';
import Notifications from '..//Notifications';
import ReactPlayer from 'react-player';
import editsVideo from '../../assets/anims/videos/popups/account_warn_ff.mp4';
import sendWarnVideo from '../../assets/anims/videos/popups/send_warn_ff.mp4';
import withdrawVideo from '../../assets/anims/videos/popups/withdraw_warn_ff.mp4';
import buyPesaWarnVideo from '../../assets/anims/videos/popups/buy_pesa_warn_ff.mp4';
import {
  selectOrder,
  resetRemittance,
  selectRemitDetails,
} from '../../redux/features/transaction/remittanceSlice';
import useUserRole from '../../utils/useUserRole';
import { get_notifictaions, selectNotifications } from '../../redux/features/sockets/socketslice';

const Header = ({ auth = false, payment = false, account = false, chat = false }) => {
  const header = useRef(null);
  const headerMob = useRef(null);
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const userAva = useSelector(selectAvatar);
  const userProfile = useSelector(selectAccount);
  const userToken = useSelector(selectFirebaseJWT);
  const userNoti = useSelector(selectNotifications);
  const role = useUserRole();
  const isAgent = useUserRole('agent');
  const [anchorEl, setAnchorEl] = useState(null);
  const [userName] = useState(
    userProfile?.personalInfo?.firstName?.[0] + userProfile?.personalInfo?.lastName?.[0] || 'PU'
  );
  const [openPopUp, setOpenPopUp] = useState(false);
  const remittanceID = useSelector(selectRemitDetails)?.remitID;
  const transferData = useSelector(selectTransferData);
  const statusOrder = useSelector((state) => state.remittance.order?.type || 'outgoing');
  const open = Boolean(anchorEl);
  const depositBuyFlow =
    pathname.startsWith('/user/buy-pesa') || pathname.startsWith('/user/deposit/');
  const sendFlow = pathname.startsWith(`/${role}/send/`);
  const withdFlow = pathname.startsWith(`/${role}/withdraw`);
  const editsFlow = pathname.startsWith(`/${role}/account/edits`);
  const deleteFlow = pathname.startsWith(`/${role}/account/edits/delete`);
  const declinedFlow = pathname.startsWith(`/${role}/chat/declined`);
  const buyPesaFlow = pathname.startsWith(`/${role}/deposit/buy-pesa`);
  const location = useLocation();
  const order = useSelector(selectOrder);
  const handleClick = (e) => {
    open ? (document.body.style = 'overflow: auto') : (document.body.style = 'overflow: hidden');
    open ? setAnchorEl(null) : setAnchorEl(e.currentTarget);
  };

  const togglePopUp = () => {
    // console.log('clicked');
    // const inputArray = Array.from(document.querySelectorAll('.item-count .MuiInputBase-input'));
    // // Check if any input element has a value greater than 0
    // const hasValueGreaterThanZero = inputArray.some((input) => {
    //   const numericValue = parseFloat(input.value);
    //   return !isNaN(numericValue) && numericValue > 0;
    // });

    // if (location.pathname === '/user/deposit/buy-pesa' && !hasValueGreaterThanZero) {
    //   const logoElement = document.querySelector('.c-header-logo');
    //   setTimeout(() => {
    //     if (logoElement) logoElement.click();
    //   }, 100);
    // } else {
    //   setOpenPopUp(!openPopUp);
    //   dispatch(setTransferData(null));
    // }
    setOpenPopUp(!openPopUp);
    dispatch(setTransferData(null));
  };

  const closePopup = () => {
    dispatch(setTransferData(null));
    dispatch(resetRemittance(null));
    setOpenPopUp(false);
  };

  useEffect(() => {
    Object.keys(userNoti).length === 0 &&
      dispatch(get_notifictaions({ currentPage: 1, limit: 10 }));
  }, []);

  return (
    <>
      <header className={`c-header ${auth ? 'auth' : ' '}`} ref={header}>
        <a
          className={`c-header-logo ${open ? 'opened-menu' : ''}`}
          onClick={() => {
            if (remittanceID || transferData) {
              togglePopUp();
            } else if (userToken) {
              navigate(`/${role}/dashboard`);
            } else {
              navigate('/');
            }
          }}
        >
          <img src={logo} alt='site logo' />
        </a>
        <>
          {!auth && !payment && !account && !chat && (
            <>
              <nav className='c-header-menu' ref={headerMob}>
                <ul>
                  {userToken && role ? (
                    <li>
                      <Link to={`/${role}/dashboard`} className='el-button'>
                        Account
                      </Link>
                    </li>
                  ) : (
                    <>
                      <li>
                        <Button
                          component={Link}
                          to='/auth/login'
                          variant='contained'
                          className='el-button'
                        >
                          Log in
                        </Button>
                      </li>

                      <li>
                        <Button
                          component={Link}
                          to='/auth/signup'
                          variant='outlined'
                          className='el-button orange'
                        >
                          Sign Up
                        </Button>
                      </li>
                    </>
                  )}
                </ul>

                <div className='c-header-menu-gamb'>
                  <Button
                    className={open ? 'active' : ''}
                    id='basic-button'
                    aria-controls={open ? 'basic-menu' : undefined}
                    aria-haspopup='true'
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick}
                  >
                    <svg
                      id='icon-gamburger'
                      width='100%'
                      height='100%'
                      viewBox='0 0 42 42'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <rect width='42' height='42' rx='5' fill='#2C2F32' />
                      <line
                        className='line line1'
                        x1='11'
                        y1='14'
                        x2='21'
                        y2='14'
                        stroke='white'
                        strokeWidth='2'
                      />
                      <line
                        className='line line2'
                        x1='11'
                        y1='20'
                        x2='31'
                        y2='20'
                        stroke='white'
                        strokeWidth='2'
                      />
                      <line
                        className='line line3'
                        x1='21'
                        y1='26'
                        x2='31'
                        y2='26'
                        stroke='white'
                        strokeWidth='2'
                      />
                    </svg>

                    <span className='c-header-menu-gamb--title'>{!open ? 'Menu' : 'Close'} </span>
                  </Button>
                </div>
              </nav>

              <div className={`menu ${open ? 'opened-menu' : ''}`}>
                <span className='menu--bg' onClick={handleClick}></span>

                <Slide direction='left' in={open} mountOnEnter unmountOnExit>
                  {
                    <div className='menu-box'>
                      <span className='menu-close' onClick={handleClick}>
                        <Icon name='close' onClick={handleClick} />
                      </span>

                      <ul className='menu-list'>
                        <li className='menu-list-item' onClick={handleClick}>
                          <NavLink to={'/'}>Home</NavLink>
                        </li>

                        <li className='menu-list-item' onClick={handleClick}>
                          <NavLink to={'/about'}>About us</NavLink>
                        </li>

                        <li className='menu-list-item' onClick={handleClick}>
                          <a target={'_blank'} href={'https://pesatoken.io'} rel='noreferrer'>
                            Pesa Token
                          </a>
                        </li>

                        <li className='menu-list-item' onClick={handleClick}>
                          <NavLink to={'/blog'}>Blog</NavLink>
                        </li>

                        <li className='menu-list-item' onClick={handleClick}>
                          <NavLink to={'/careers'}>Careers</NavLink>
                        </li>

                        <li className='menu-list-item' onClick={handleClick}>
                          <NavLink to={'/contact'}>Contact us</NavLink>
                        </li>
                      </ul>

                      <div className='menu-store'>
                        <a href='http://google.com' target='_blank' rel='noopener noreferrer'>
                          <img src={google} alt='' />
                        </a>

                        <a href='http://apple.com' target='_blank' rel='noopener noreferrer'>
                          <img src={apple} alt='' />
                        </a>
                      </div>

                      <div className='menu-contact'>
                        Feel free to contact us on your enquiries{' '}
                        <a target={'_blank'} href='mailto:team@pesabase.com' rel='noreferrer'>
                          team@pesabase.com
                        </a>
                      </div>
                    </div>
                  }
                </Slide>
              </div>
            </>
          )}

          {payment && (
            <>
              {location.pathname === '/user/send/system' ||
              location.pathname === '/agent/send/system' ||
              location.pathname === '/user/deposit/system' ||
              location.pathname === '/user/deposit/ssp' ||
              location.pathname === '/user/deposit/pesabase' ||
              location.pathname === '/user/deposit/mpesa' ? (
                <Link className='close-flow' to={`/${role}/dashboard`}>
                  <svg
                    width='100%'
                    height='100%'
                    viewBox='0 0 40 40'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <g id='icons/X'>
                      <path
                        id='Union'
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M8.68645 8.68547C8.29593 9.076 8.29593 9.70916 8.68645 10.0997L18.586 19.9993L8.68645 29.8988C8.29592 30.2894 8.29592 30.9225 8.68645 31.313C9.07697 31.7036 9.71014 31.7036 10.1007 31.313L20.0002 21.4135L29.8997 31.3129C30.2902 31.7034 30.9233 31.7034 31.3139 31.3129C31.7044 30.9224 31.7044 30.2892 31.3139 29.8987L21.4144 19.9993L31.3139 10.0998C31.7044 9.70931 31.7044 9.07615 31.3139 8.68562C30.9233 8.2951 30.2902 8.2951 29.8997 8.68562L20.0002 18.585L10.1007 8.68547C9.71014 8.29495 9.07698 8.29495 8.68645 8.68547Z'
                      />
                    </g>
                  </svg>
                </Link>
              ) : (
                <Link className='close-flow' onClick={() => togglePopUp()}>
                  <svg
                    width='40'
                    height='40'
                    viewBox='0 0 40 40'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <g id='icons/X'>
                      <path
                        id='Union'
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M8.68645 8.68547C8.29593 9.076 8.29593 9.70916 8.68645 10.0997L18.586 19.9993L8.68645 29.8988C8.29592 30.2894 8.29592 30.9225 8.68645 31.313C9.07697 31.7036 9.71014 31.7036 10.1007 31.313L20.0002 21.4135L29.8997 31.3129C30.2902 31.7034 30.9233 31.7034 31.3139 31.3129C31.7044 30.9224 31.7044 30.2892 31.3139 29.8987L21.4144 19.9993L31.3139 10.0998C31.7044 9.70931 31.7044 9.07615 31.3139 8.68562C30.9233 8.2951 30.2902 8.2951 29.8997 8.68562L20.0002 18.585L10.1007 8.68547C9.71014 8.29495 9.07698 8.29495 8.68645 8.68547Z'
                      />
                    </g>
                  </svg>
                </Link>
              )}

              <Popup open={openPopUp} togglePopUp={togglePopUp}>
                <div className='modal-content'>
                  <div className='modal-img'>
                    <ReactPlayer
                      className='player'
                      controls={false}
                      playing={true}
                      loop={true}
                      muted={true}
                      playsinline={true}
                      url={(sendFlow || declinedFlow)
                            ?  sendWarnVideo
                            : withdFlow
                              ? withdrawVideo
                              : buyPesaFlow
                                ? buyPesaWarnVideo
                                : editsVideo
                          }
                      width='100%'
                      height='100%'
                    />
                  </div>

                  <div className='modal-desc'>
                    {depositBuyFlow && (
                      <>
                      <div className='modal-desc-title'>Are you sure want to cancel purchase?</div>
                      <div className='el-text-m modal-desc-text'>
                          Please be aware that all entered data will be lost.
                        </div>
                      </>

                    )}

                    {sendFlow && (
                      <>
                      <div className='modal-desc-title'>Are you sure want to cancel send?</div>
                      <div className='el-text-m modal-desc-text'>
                          Please be aware that all entered data will be lost.
                        </div>
                      </>

                    )}
                    {declinedFlow && (
                      order?.type === "outgoing" ? (
                        <div className='modal-desc-title'>Are you sure want to cancel destination change?</div>
                      )
                      :(
                        <div className='modal-desc-title'>Are you sure want to cancel decline?</div>
                      )
                    )}

                    {withdFlow && (
                       <>
                      <div className='modal-desc-title'>Are you sure want to cancel withdraw?</div>
                      <div className='el-text-m modal-desc-text'>
                          Please be aware that all entered data will be lost.
                        </div>
                      </>

                    )}

                    {pathname.startsWith === '/user/account/edits' && (
                       <>
                        <div className='modal-desc-title'>
                          Are you sure want to cancel personal information change?
                        </div>

                      </>

                    )}

                    {deleteFlow && (
                      <div className='modal-desc-title'>
                        Are you sure want to cancel account delete?
                      </div>
                    )}

                  </div>

                  <div className='modal-btns'>
                    <Link
                      className='el-button el-button-btn'
                      to={
                        declinedFlow
                          ? statusOrder === 'incoming'
                            ? `/agent/incoming-orders`
                            : `/agent/outgoing-orders`
                          : editsFlow
                            ? `/${role}/account`
                            : `/${role}/dashboard`
                      }
                      variant='contained'
                      onClick={closePopup}
                    >
                      Yes, I want to cancel
                    </Link>
                    <Button
                      className='el-button orange el-button-continue'
                      variant='contained'
                      onClick={() => togglePopUp()}
                    >
                      No, I want to continue
                    </Button>
                  </div>
                </div>
              </Popup>
            </>
          )}

          {!account && chat && (
            <>
              {location.pathname === '/agent/chat' ? (
                <div className='c-acc-header'>
                  <Container disableGutters>
                    <div className='user-box'>
                      <Notifications />
                      <Link
                        className='close-flow close-flow-chat'
                        to={
                          isAgent
                            ? statusOrder === 'outgoing'
                              ? '/agent/outgoing-orders'
                              : statusOrder === 'incoming'
                                ? '/agent/incoming-orders'
                                : statusOrder === 'pool'
                                  ? '/agent/transactions-pool'
                                  : '/agent/dashboard'
                            : '/user/dashboard'
                        }
                      >
                        <svg
                          width='100%'
                          height='100%'
                          viewBox='0 0 40 40'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <g id='icons/X'>
                            <path
                              id='Union'
                              fillRule='evenodd'
                              clipRule='evenodd'
                              d='M8.68645 8.68547C8.29593 9.076 8.29593 9.70916 8.68645 10.0997L18.586 19.9993L8.68645 29.8988C8.29592 30.2894 8.29592 30.9225 8.68645 31.313C9.07697 31.7036 9.71014 31.7036 10.1007 31.313L20.0002 21.4135L29.8997 31.3129C30.2902 31.7034 30.9233 31.7034 31.3139 31.3129C31.7044 30.9224 31.7044 30.2892 31.3139 29.8987L21.4144 19.9993L31.3139 10.0998C31.7044 9.70931 31.7044 9.07615 31.3139 8.68562C30.9233 8.2951 30.2902 8.2951 29.8997 8.68562L20.0002 18.585L10.1007 8.68547C9.71014 8.29495 9.07698 8.29495 8.68645 8.68547Z'
                            />
                          </g>
                        </svg>
                      </Link>
                    </div>
                  </Container>
                </div>
              ) : (
                <></>
              )}
            </>
          )}

          {account && !chat && (
            <div className='c-acc-header'>
              <Container disableGutters>
                <div className='user-box'>
                  <Notifications />

                  <div className='user-box-avatar'>
                    {userAva ? (
                      <div className='user-box-avatar-img'>
                        <img src={userAva} alt='' />
                      </div>
                    ) : (
                      <div className='user-box-avatar-init'>{userName}</div>
                    )}
                  </div>
                </div>
              </Container>
            </div>
          )}
        </>
      </header>
    </>
  );
};

Header.propTypes = {
  auth: PropTypes.bool,
};

export default Header;
