import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Review from '../../../../components/Review';
import ArrowBack from '../../../../components/ArrowBack';
import { getOTP } from '../../../../api';
import closeFlow from '../../../../utils/closeFlow';
import { toast } from 'react-toastify';
import { LoadingButton } from '@mui/lab';

const WithdrawReview = ({ changeStep, flowData, userToken }) => {
  const [localLoading, setLocalLoading] = useState(false);

  const goToTheNextStep = async () => {
    try {
      setLocalLoading(true);
      await getOTP(userToken);
      changeStep(1);
    } catch (error) {
      console.log(error);
      toast.error(`Something went wrong...${error?.message || 'Oops!'}`);
    } finally {
      setLocalLoading(false);
    }
  };

  return (
    <div className='send-step'>
      <ArrowBack handle={() => changeStep(-1)} />

      <div className='payment-title'>
        <h6 className='el-title-h6 med'>Review details of your transfer</h6>
      </div>

      <div className='send-step-content'>
        <Review userToken={userToken} changeStep={changeStep} transferData={flowData} withdraw={true} />

        <div className='send-btns'>
          <div className='send-btns-continue'>
            <LoadingButton
              loading={localLoading}
              onClick={goToTheNextStep}
              className='el-button orange'
            >
              <span>{localLoading ? 'Processing' : 'Continue'}</span>
            </LoadingButton>
          </div>
          <div className='send-btns-cancel'>
            <div className='send-btns-cancel-wrap'>
              <span className='el-link-m el-button-link' variant='contained' onClick={closeFlow}>
                Cancel
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

WithdrawReview.propTypes = {
  changeStep: PropTypes.func.isRequired,
  flowData: PropTypes.object.isRequired,
  userToken: PropTypes.string.isRequired,
};
export default WithdrawReview;
