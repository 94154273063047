import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  parsePhoneNumberFromString,
  isValidPhoneNumber,
  validatePhoneNumberLength,
} from 'libphonenumber-js';
import PhoneInput from 'react-phone-input-2';
import ClearIcon from '@mui/icons-material/Clear';
import axios from 'axios';

const PhoneField = ({ searchPhone, setSearchPhone, submitFn }) => {
  // State to manage phone number validity.
  const [userLocation, setUserLocation] = useState('ke');
  const [dialCode, setDialCode] = useState('');

  const getCountryName = async () => {
    try {
      const response = await axios.get('https://api.country.is');
      const country = response?.data?.country;

      if (country) {
        setUserLocation(country.toLowerCase());
      }
    } catch (error) {
      console.log(error);
    }
  };

  const checkIsValidPhone = (phoneNumber, country) => {
    setDialCode(country.dialCode);
    const countryCode = country.countryCode.toUpperCase();
    const parsedNumber = parsePhoneNumberFromString(phoneNumber, countryCode);

    setSearchPhone({
      phoneNumber: `+${phoneNumber}`,
      isValid:
        (parsedNumber?.isValid() &&
          isValidPhoneNumber(parsedNumber?.number) &&
          !validatePhoneNumberLength(parsedNumber?.number) &&
          phoneNumber.length > 10) ||
        false,
      dialCode: country.dialCode,
    });
  };

  const cleanSearch = () => {
    setSearchPhone({
      phoneNumber: dialCode,
      isValid: false,
      dialCode: dialCode,
    });
  };

  useEffect(() => {
    !searchPhone && getCountryName();
  }, []);

  return (
    <div className='el-input-search el-input-phone el-input-phone-in Mui-focused'>
      <label className='el-input-phone-label'>Phone number</label>

      <PhoneInput
        country={userLocation}
        value={searchPhone}
        placeholder=''
        onFocus={(e) => {
          e.target.closest('.el-input-phone').classList.add('Mui-focused');
        }}
        onBlur={(e) => {
          if (e.target.value === '') {
            e.target.closest('.el-input-phone').classList.remove('Mui-focused');
          }
        }}
        onChange={(value, country, e) => {
          checkIsValidPhone(value, country);
          if (
            value.length > 0 &&
            !e.target.closest('.el-input-phone').classList.contains('Mui-focused')
          ) {
            setTimeout(() => {
              e.target.closest('.el-input-phone').classList.add('Mui-focused');
            }, 100);
          }
        }}
        onKeyDown={(e) => (e.keyCode === 13 && submitFn) && submitFn(e)}
        inputProps={{
          autoFocus: true,
        }}
      />

      {searchPhone.length > dialCode.length && (
        <div
          className='MuiInputAdornment-root MuiInputAdornment-positionEnd MuiInputAdornment-outlined MuiInputAdornment-sizeMedium css-1laqsz7-MuiInputAdornment-root'
          onClick={() => cleanSearch()}
        >
          <ClearIcon style={{ cursor: 'pointer' }} />
        </div>
      )}

      <fieldset aria-hidden='true' className='el-input-phone-fieldset'>
        <legend className='el-input-phone-fieldset-legend'>
          <span>Phone number</span>
        </legend>
      </fieldset>
    </div>
  );
};

PhoneField.propTypes = {
  handle: PropTypes.any,
};

export default PhoneField;
