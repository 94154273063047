import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import AgentTxItemSocket from '../../agent/components/AgentTxItemSocket';
import { v4 as uuidv4 } from 'uuid';
import { updateAgentStatus } from '../../api';
import { toast } from 'react-toastify';
import { FormControlLabel, FormGroup, Switch } from '@mui/material';
import availabilityBg from '../../assets/img/p-dashboard/availability_bg.png';
import availabilityImg from '../../assets/img/p-dashboard/availability.png';
import { useDispatch, useSelector } from 'react-redux';
import { selectAccount, selectFirebaseJWT, setAccount } from '../../redux/features/user/userSlice';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

const DashboardAgent = ({
  attentionOutgoingTxData,
  attentionIncomingTxData,
  localLoading,
  children,
}) => {
  const agentAvailability = useSelector(selectAccount)?.availability;
  const [checked, setChecked] = useState(agentAvailability);
  const [clicked, setClicked] = useState(false);
  const userToken = useSelector(selectFirebaseJWT);
  const dispatch = useDispatch();
  
  const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      fontSize: theme.typography.pxToRem(12),
      lineHeight: '150%',
    },
  }));

  const handleSetChecked = async () => {
    try {
      setClicked(true);
      const response = await updateAgentStatus(userToken, !checked);

      if (response?.status === 200) {
        toast.success('Agent availability updated');
        dispatch(setAccount({ availability: !checked }));
        setChecked(!checked);
      } else {
        throw new Error(response.message);
      }
      setClicked(false);
    } catch (error) {
      console.log(error);
      toast.error(`Something went wrong...${error?.message || 'Oops!'}`);
    }
  };

  return (
    <>
      <div className='dashboard-availability'>
        <span className='dashboard-availability--bg'>
          <img src={availabilityBg} alt='' />
        </span>

        <div className='dashboard-availability-desc'>
          <h6>Please, keep your availability updated!</h6>

          <FormGroup className={`${clicked ? 'processing' : ''}`}>
            <FormControlLabel
              control={<Switch disabled={clicked} checked={checked} onChange={(e) => handleSetChecked(e)} />}
              label={
                <>
                  <div className='el-text-m med title'>
                    Available for receiving transactions
                    <span className='dashboard-availability-desc-noti'>
                      <HtmlTooltip
                        title='SSP balance is not included into the Total balance calculation'
                        placement='bottom'
                        className='c-tooltip'
                      >
                        <span className='wallet-info-el-not'>
                          <svg
                            width='100%'
                            height='100%'
                            viewBox='0 0 16 17'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <g id='icons/notification'>
                              <path
                                id='Rectangle 86'
                                d='M7 8.5C7 7.94772 7.44772 7.5 8 7.5C8.55228 7.5 9 7.94772 9 8.5L9 11.5C9 12.0523 8.55228 12.5 8 12.5C7.44771 12.5 7 12.0523 7 11.5L7 8.5Z'
                                fill='#6D757D'
                              />
                              <path
                                id='Rectangle 87'
                                d='M7 5.5C7 4.94772 7.44772 4.5 8 4.5C8.55228 4.5 9 4.94772 9 5.5C9 6.05229 8.55228 6.5 8 6.5C7.44771 6.5 7 6.05228 7 5.5Z'
                                fill='#6D757D'
                              />
                              <path
                                d='M15 8.5C15 12.366 11.866 15.5 8 15.5C4.13401 15.5 1 12.366 1 8.5C1 4.63401 4.13401 1.5 8 1.5C11.866 1.5 15 4.63401 15 8.5Z'
                                stroke='#6D757D'
                                strokeWidth='2'
                              />
                            </g>
                          </svg>
                        </span>
                      </HtmlTooltip>
                    </span>
                  </div>

                  <span className='el-text-s text'>
                    When set to "Available", other agents can find you and assign transactions to
                    you. Set your availability to "Not available" if you're not ready to do
                    withdraws.
                  </span>
                </>
              }
            />
          </FormGroup>
        </div>

        <div className='dashboard-availability-img'>
          <img src={availabilityImg} />
        </div>
      </div>

      {children}

      {attentionOutgoingTxData?.length > 0 && (
        <>
          <div className='dashboard-transactions'>
            <div className='dashboard-transactions-top'>
              <h6 className='el-title-h6 med'>Pending outgoing orders:</h6>

              <Link
                className='dashboard-transactions-top-link el-text-s med'
                to='/agent/outgoing-orders'
              >
                <span className='link-icon'>
                  <svg
                    width='100%'
                    height='100%'
                    viewBox='0 0 24 24'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      fillRule='evenodd'
                      clipRule='evenodd'
                      d='M19.1829 11.2476C19.0612 11.5155 18.7942 11.6875 18.5 11.6875L15.2777 11.6875C15.2769 11.9159 15.269 12.1845 15.2482 12.4838C15.182 13.4327 14.9831 14.7144 14.4419 16.0082C13.8985 17.3072 13.0044 18.6304 11.548 19.6252C10.0898 20.6212 8.12319 21.25 5.49999 21.25C5.08577 21.25 4.74999 20.9142 4.74999 20.5C4.74999 20.0858 5.08577 19.75 5.49999 19.75C6.22507 19.75 6.76984 19.4294 7.21825 18.8471C7.68706 18.2383 8.03067 17.3607 8.26992 16.3438C8.65481 14.7081 8.73113 12.891 8.74625 11.6875L5.49999 11.6875C5.20578 11.6875 4.93875 11.5155 4.8171 11.2476C4.69546 10.9797 4.74166 10.6655 4.93526 10.444L11.4353 3.00646C11.5777 2.84349 11.7836 2.75 12 2.75C12.2164 2.75 12.4223 2.84349 12.5647 3.00646L19.0647 10.444C19.2583 10.6655 19.3045 10.9797 19.1829 11.2476ZM8.71073 19.3216C9.49841 19.0839 10.1543 18.7606 10.702 18.3865C11.8706 17.5883 12.6014 16.521 13.0581 15.4293C13.5169 14.3325 13.693 13.2235 13.7518 12.3795C13.7811 11.9597 13.7809 11.6113 13.7738 11.3708C13.7702 11.2507 13.7648 11.1579 13.7606 11.0971C13.7585 11.0666 13.7566 11.0443 13.7554 11.0305C13.7548 11.0236 13.7544 11.0188 13.7541 11.0163L13.7541 11.0155C13.754 11.0151 13.754 11.0147 13.7539 11.0143C13.7322 10.8034 13.8008 10.5931 13.9428 10.4355C14.085 10.2776 14.2875 10.1875 14.5 10.1875L16.8485 10.1875L12 4.63972L7.15151 10.1875L9.49999 10.1875C9.9142 10.1875 10.25 10.5233 10.25 10.9375L10.25 10.9538C10.25 12.0178 10.25 14.4775 9.73005 16.6874C9.5122 17.6133 9.19267 18.5436 8.71073 19.3216Z'
                      fill='#E98000'
                    />
                  </svg>
                </span>
                All outgoing orders
              </Link>
            </div>

            {attentionOutgoingTxData.length > 0 && !localLoading.txLoading && (
              <div className='dashboard-transactions-content'>
                <div className='tx-block'>
                  <div className='tx-table'>
                    {attentionOutgoingTxData.map((tx) => (
                      <div className='tx-item-inner' key={uuidv4()}>
                        <>
                          <AgentTxItemSocket
                            tx={tx}
                            key={uuidv4()}
                            txOutgoing={true}
                            txAttention={true}
                            txChat={true}
                          />
                        </>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )}

            {attentionOutgoingTxData.length === 0 && !localLoading.txLoading && (
              <>
                <div className='dashboard-contacts-empty el-text-m'>
                  <span className='icon'>💵</span>
                  No recent transactions yet. Please,
                  <Link to={'/agent/send/system'} className='el-link-m'>
                    {' '}
                    deposit some money{' '}
                  </Link>
                  to start using Pesabase.
                </div>
              </>
            )}
          </div>
        </>
      )}

      {attentionIncomingTxData?.length > 0 && (
        <>
          <div className='dashboard-transactions'>
            <div className='dashboard-transactions-top'>
              <h6 className='el-title-h6 med'>Pending incoming orders:</h6>

              <Link
                className='dashboard-transactions-top-link el-text-s med'
                to='/agent/incoming-orders'
              >
                <span className='link-icon'>
                  <svg
                    width='100%'
                    height='100%'
                    viewBox='0 0 24 24'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      fillRule='evenodd'
                      clipRule='evenodd'
                      d='M4.8171 12.7524C4.93875 12.4845 5.20578 12.3125 5.49998 12.3125L8.72228 12.3125C8.72312 12.0841 8.73093 11.8155 8.7518 11.5162C8.81795 10.5673 9.0169 9.28558 9.55808 7.99182C10.1014 6.69284 10.9956 5.36956 12.452 4.37482C13.9102 3.37879 15.8768 2.75 18.5 2.75C18.9142 2.75 19.25 3.08579 19.25 3.5C19.25 3.91421 18.9142 4.25 18.5 4.25C17.7749 4.25 17.2301 4.57058 16.7817 5.1529C16.3129 5.76169 15.9693 6.6393 15.73 7.65615C15.3452 9.29193 15.2688 11.109 15.2537 12.3125L18.5 12.3125C18.7942 12.3125 19.0612 12.4845 19.1829 12.7524C19.3045 13.0203 19.2583 13.3345 19.0647 13.556L12.5647 20.9935C12.4223 21.1565 12.2164 21.25 12 21.25C11.7836 21.25 11.5777 21.1565 11.4353 20.9935L4.93525 13.556C4.74165 13.3345 4.69545 13.0203 4.8171 12.7524ZM15.2892 4.67835C14.5016 4.91613 13.8456 5.23941 13.298 5.61346C12.1293 6.41169 11.3985 7.47904 10.9419 8.57067C10.4831 9.66755 10.307 10.7765 10.2482 11.6205C10.2189 12.0403 10.219 12.3887 10.2262 12.6292C10.2298 12.7493 10.2351 12.8421 10.2394 12.9029C10.2415 12.9334 10.2433 12.9557 10.2445 12.9695C10.2452 12.9764 10.2456 12.9812 10.2458 12.9837L10.2459 12.9845C10.246 12.9849 10.246 12.9853 10.246 12.9857C10.2678 13.1966 10.1992 13.4069 10.0572 13.5645C9.91499 13.7224 9.71247 13.8125 9.49998 13.8125L7.1515 13.8125L12 19.3603L16.8485 13.8125L14.5 13.8125C14.0858 13.8125 13.75 13.4767 13.75 13.0625L13.75 13.0462C13.75 11.9822 13.7499 9.52252 14.2699 7.31259C14.4878 6.38673 14.8073 5.45638 15.2892 4.67835Z'
                      fill='#E98000'
                    />
                  </svg>
                </span>
                All incoming orders
              </Link>
            </div>

            {attentionIncomingTxData.length > 0 && !localLoading.txLoading && (
              <div className='dashboard-transactions-content'>
                <div className='tx-block'>
                  <div className='tx-table'>
                    {attentionIncomingTxData.map((tx) => (
                      <div className='tx-item-inner' key={uuidv4()}>
                        <>
                          <AgentTxItemSocket
                            tx={tx}
                            key={uuidv4()}
                            txIncoming={true}
                            txAttention={true}
                            txChat={true}
                          />
                        </>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )}

            {attentionIncomingTxData.length === 0 && !localLoading.txLoading && (
              <>
                <div className='dashboard-contacts-empty el-text-m'>
                  <span className='icon'>💵</span>
                  No recent transactions yet. Please,
                  <Link to={'/agent/send/system'} className='el-link-m'>
                    {' '}
                    deposit some money{' '}
                  </Link>
                  to start using Pesabase.
                </div>
              </>
            )}
          </div>
        </>
      )}
    </>
  );
};

DashboardAgent.propTypes = {
  attentionOutgoingTxData: PropTypes.array,
  attentionIncomingTxData: PropTypes.array,
  localLoading: PropTypes.object,
};

export default DashboardAgent;
