import React from 'react';
import { Button } from '@mui/material';
import PropTypes from 'prop-types';
import transformNumbers from '../../utils/transformNumbers';
import ShortenedAddress from '../ShortenedAddress';

const Review = ({ changeStep, transferData, buyData, remitData, agentToAgentData, MPesaData, withdraw }) => {
  const { userReceiver, userReview } = transferData || agentToAgentData || MPesaData || {};
  const { remitDetails, sender, receiver } = remitData || {};


  return (
    <div className='review-box'>
      {transferData && (
        <>
          <div className='block'>
            <div className='block-wrap'>
              <div className='block-top'>
                <div className='block-title'>Receiver details:</div>

                <div className='block-edit'>
                  <Button className='el-button' onClick={() => changeStep(-2)} variant='contained'>
                    <span className='el-button-icon'>
                      <svg
                        width='100%'
                        height='100%'
                        viewBox='0 0 28 28'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path d='M5.79809 22.172H7.40675L17.3173 12.2615L15.7086 10.6528L5.79809 20.5633V22.172ZM22.2294 10.5954L17.346 5.76936L18.9547 4.1607C19.3951 3.72023 19.9363 3.5 20.5783 3.5C21.2202 3.5 21.761 3.72023 22.2007 4.1607L23.8094 5.76936C24.2498 6.20983 24.4797 6.74146 24.4988 7.36424C24.518 7.98702 24.3073 8.51826 23.8668 8.95797L22.2294 10.5954ZM20.5633 12.2902L8.38344 24.4701H3.5V19.5866L15.6799 7.40675L20.5633 12.2902ZM16.5129 11.4571L15.7086 10.6528L17.3173 12.2615L16.5129 11.4571Z' />
                      </svg>
                    </span>
                    <span>Edit</span>
                  </Button>
                </div>
              </div>

              <div className='block-content'>
                {userReceiver.name && (
                  <div className='block-content-item'>
                    <div className='el-text-m block-content-label'>Name</div>
                    <div className='el-text-m block-content-text name'>
                      {userReceiver.name.trim() !== '' ? userReceiver.name : 'Pesabase user'}
                    </div>
                  </div>
                )}

                {userReceiver?.address && withdraw && (
                  <div className='block-content-item'>
                    <div className='el-text-m block-content-label'>Wallet address</div>
                    <div className='el-text-m block-content-text name'>
                      {ShortenedAddress({ address: userReceiver.address })}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className='block'>
            <div className='block-wrap'>
              <div className='block-top'>
                <div className='block-title'>Transfer details:</div>
                <div className='block-edit'>
                  <Button className='el-button' onClick={() => changeStep(-1)} variant='contained'>
                    <span className='el-button-icon'>
                      <svg
                        width='100%'
                        height='100%'
                        viewBox='0 0 28 28'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path d='M5.79809 22.172H7.40675L17.3173 12.2615L15.7086 10.6528L5.79809 20.5633V22.172ZM22.2294 10.5954L17.346 5.76936L18.9547 4.1607C19.3951 3.72023 19.9363 3.5 20.5783 3.5C21.2202 3.5 21.761 3.72023 22.2007 4.1607L23.8094 5.76936C24.2498 6.20983 24.4797 6.74146 24.4988 7.36424C24.518 7.98702 24.3073 8.51826 23.8668 8.95797L22.2294 10.5954ZM20.5633 12.2902L8.38344 24.4701H3.5V19.5866L15.6799 7.40675L20.5633 12.2902ZM16.5129 11.4571L15.7086 10.6528L17.3173 12.2615L16.5129 11.4571Z' />
                      </svg>
                    </span>
                    <span>Edit</span>
                  </Button>
                </div>
              </div>
              <div className='block-content'>
                {userReview.paymentSystem && (
                  <div className='block-content-item'>
                    <div className='el-text-m block-content-label'>Payment system</div>
                    <div className='el-text-m block-content-text'>
                      {userReview.paymentSystem === 'm-pesa' ? 'M-Pesa' : 'Pesabase'}
                    </div>
                  </div>
                )}

                {userReview.sendValue && (
                  <div className='block-content-item'>
                    <div className='el-text-m block-content-label'>You send exactly</div>
                    <div className='el-text-m block-content-text upper'>
                      <b>
                        <span>{userReview.sendValue}</span>{' '}
                        <span className='currency'> {userReview.currency}</span>
                      </b>
                    </div>
                  </div>
                )}

                {userReview.transactionFee && (
                  <div className='block-content-item'>
                    <div className='el-text-m block-content-label'>Transaction fee</div>
                    <div className='el-text-m block-content-text'>
                      {transformNumbers(userReview.transactionFee, 3)}{' '}
                      <span className='currency'> {userReview.transactionFeeCurrency}</span>
                    </div>
                  </div>
                )}

                {!userReview.totalMixed ? (
                  <div className='block-content-item'>
                    <div className='block-content-label'>Total</div>
                    <div className='block-content-text upper'>
                      <b>
                        {transformNumbers(userReview.total, 2)}{' '}
                        <span className='currency'> {userReview.transactionFeeCurrency}</span>
                      </b>
                    </div>
                  </div>
                ) : (
                  <div className='block-content-item'>
                    <div className='block-content-label'>Total</div>
                    <div className='block-content-text upper'>
                      <b>
                        {transformNumbers(userReview.sendValue, 2)} {userReview.currency} +{' '}
                        {transformNumbers(userReview.transactionFee, 3)}
                        <span className='currency'> {userReview.transactionFeeCurrency}</span>
                      </b>
                    </div>
                  </div>
                )}

                {userReview.equivalent && (
                  <div className='block-content-item'>
                    <div className='block-content-label'>USD Equivalent</div>
                    <div className='block-content-text'>
                      <span>{parseFloat(userReview.equivalent).toFixed(3)}</span> USD
                    </div>
                  </div>
                )}

                {userReview.comment && (
                  <div className='block-content-item comment'>
                    <div className='block-content-label'>Comment</div>
                    <div className='block-content-text comment'>{userReview.comment}</div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </>
      )}

      {buyData && (
        <div className='block'>
          <div className='block-wrap'>
            <div className='block-top'>
              <div className='block-title'>Transfer details:</div>

              <div className='block-edit'>
                <Button className='el-button' onClick={() => changeStep(-1)} variant='contained'>
                  <span className='el-button-icon'>
                    <svg
                      width='100%'
                      height='100%'
                      viewBox='0 0 28 28'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path d='M5.79809 22.172H7.40675L17.3173 12.2615L15.7086 10.6528L5.79809 20.5633V22.172ZM22.2294 10.5954L17.346 5.76936L18.9547 4.1607C19.3951 3.72023 19.9363 3.5 20.5783 3.5C21.2202 3.5 21.761 3.72023 22.2007 4.1607L23.8094 5.76936C24.2498 6.20983 24.4797 6.74146 24.4988 7.36424C24.518 7.98702 24.3073 8.51826 23.8668 8.95797L22.2294 10.5954ZM20.5633 12.2902L8.38344 24.4701H3.5V19.5866L15.6799 7.40675L20.5633 12.2902ZM16.5129 11.4571L15.7086 10.6528L17.3173 12.2615L16.5129 11.4571Z' />
                    </svg>
                  </span>
                  <span>Edit</span>
                </Button>
              </div>
            </div>

            <div className='block-content'>
              {buyData?.youPay && (
                <div className='block-content-item'>
                  <div className='block-content-label'>You pay</div>
                  <div className='block-content-text'>
                    <b>{buyData.youPay.toFixed(2)} USD</b>
                  </div>
                </div>
              )}

              {buyData?.youBuy && (
                <div className='block-content-item'>
                  <div className='block-content-label'>You buy</div>
                  <div className='block-content-text'>
                    <b>{buyData.youBuy.toFixed(2)} PESA</b>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}

      {remitData && (
        <>
          <div className='block'>
            <div className='block-wrap'>
              <div className='block-top'>
                <div className='block-title'>Receiver’s cash pickup location:</div>

                <div className='block-edit'>
                  <Button
                    className='el-button'
                    onClick={() => changeStep(-4, true)}
                    variant='contained'
                  >
                    <span className='el-button-icon'>
                      <svg
                        width='100%'
                        height='100%'
                        viewBox='0 0 28 28'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path d='M5.79809 22.172H7.40675L17.3173 12.2615L15.7086 10.6528L5.79809 20.5633V22.172ZM22.2294 10.5954L17.346 5.76936L18.9547 4.1607C19.3951 3.72023 19.9363 3.5 20.5783 3.5C21.2202 3.5 21.761 3.72023 22.2007 4.1607L23.8094 5.76936C24.2498 6.20983 24.4797 6.74146 24.4988 7.36424C24.518 7.98702 24.3073 8.51826 23.8668 8.95797L22.2294 10.5954ZM20.5633 12.2902L8.38344 24.4701H3.5V19.5866L15.6799 7.40675L20.5633 12.2902ZM16.5129 11.4571L15.7086 10.6528L17.3173 12.2615L16.5129 11.4571Z' />
                      </svg>
                    </span>
                    <span>Edit</span>
                  </Button>
                </div>
              </div>

              <div className='block-content'>
                <div className='block-content-item'>
                  {remitData?.remitDetails?.pickUpLocation?.country && (
                    <>
                      <div className='block-content-label'>Country</div>
                      <div className='block-content-text'>
                        {remitData.remitDetails.pickUpLocation.country}
                      </div>
                    </>
                  )}
                </div>

                <div className='block-content-item'>
                  {remitData?.remitDetails?.pickUpLocation?.suburb && (
                    <>
                      <div className='block-content-label'>Suburb</div>
                      <div className='block-content-text'>
                        {remitData.remitDetails.pickUpLocation.suburb}
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className='block'>
            <div className='block-wrap'>
              <div className='block-top'>
                <div className='block-title'>Sender details:</div>

                <div className='block-edit'>
                  <Button
                    className='el-button'
                    onClick={() => changeStep(-3, true)}
                    variant='contained'
                  >
                    <span className='el-button-icon'>
                      <svg
                        width='100%'
                        height='100%'
                        viewBox='0 0 28 28'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path d='M5.79809 22.172H7.40675L17.3173 12.2615L15.7086 10.6528L5.79809 20.5633V22.172ZM22.2294 10.5954L17.346 5.76936L18.9547 4.1607C19.3951 3.72023 19.9363 3.5 20.5783 3.5C21.2202 3.5 21.761 3.72023 22.2007 4.1607L23.8094 5.76936C24.2498 6.20983 24.4797 6.74146 24.4988 7.36424C24.518 7.98702 24.3073 8.51826 23.8668 8.95797L22.2294 10.5954ZM20.5633 12.2902L8.38344 24.4701H3.5V19.5866L15.6799 7.40675L20.5633 12.2902ZM16.5129 11.4571L15.7086 10.6528L17.3173 12.2615L16.5129 11.4571Z' />
                      </svg>
                    </span>
                    <span>Edit</span>
                  </Button>
                </div>
              </div>

              <div className='block-content'>
                <div className='block-content-item'>
                  <div className='block-content-label'>Name</div>
                  <div className='block-content-text'>
                    {sender?.personalInfo?.firstName} {sender?.personalInfo?.lastName}
                  </div>
                </div>

                <div className='block-content-item'>
                  <div className='block-content-label'>Phone</div>
                  <div className='block-content-text'>{sender.phoneNumber}</div>
                </div>
              </div>
            </div>
          </div>

          <div className='block'>
            <div className='block-wrap'>
              <div className='block-top'>
                <div className='block-title'>Receiver details:</div>

                <div className='block-edit'>
                  <Button
                    className='el-button'
                    onClick={() => changeStep(-2, true)}
                    variant='contained'
                  >
                    <span className='el-button-icon'>
                      <svg
                        width='100%'
                        height='100%'
                        viewBox='0 0 28 28'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path d='M5.79809 22.172H7.40675L17.3173 12.2615L15.7086 10.6528L5.79809 20.5633V22.172ZM22.2294 10.5954L17.346 5.76936L18.9547 4.1607C19.3951 3.72023 19.9363 3.5 20.5783 3.5C21.2202 3.5 21.761 3.72023 22.2007 4.1607L23.8094 5.76936C24.2498 6.20983 24.4797 6.74146 24.4988 7.36424C24.518 7.98702 24.3073 8.51826 23.8668 8.95797L22.2294 10.5954ZM20.5633 12.2902L8.38344 24.4701H3.5V19.5866L15.6799 7.40675L20.5633 12.2902ZM16.5129 11.4571L15.7086 10.6528L17.3173 12.2615L16.5129 11.4571Z' />
                      </svg>
                    </span>
                    <span>Edit</span>
                  </Button>
                </div>
              </div>

              <div className='block-content'>
                <div className='block-content-item'>
                  <div className='block-content-label'>Name</div>
                  <div className='block-content-text'>
                    {receiver?.personalInfo?.firstName} {receiver?.personalInfo?.lastName}
                  </div>
                </div>

                <div className='block-content-item'>
                  <div className='block-content-label'>Phone</div>
                  <div className='block-content-text'>{receiver.phoneNumber}</div>
                </div>
              </div>
            </div>
          </div>

          <div className='block'>
            <div className='block-wrap'>
              <div className='block-top'>
                <div className='block-title'>Transfer details:</div>

                <div className='block-edit'>
                  <Button className='el-button' onClick={() => changeStep(-1)} variant='contained'>
                    <span className='el-button-icon'>
                      <svg
                        width='100%'
                        height='100%'
                        viewBox='0 0 28 28'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path d='M5.79809 22.172H7.40675L17.3173 12.2615L15.7086 10.6528L5.79809 20.5633V22.172ZM22.2294 10.5954L17.346 5.76936L18.9547 4.1607C19.3951 3.72023 19.9363 3.5 20.5783 3.5C21.2202 3.5 21.761 3.72023 22.2007 4.1607L23.8094 5.76936C24.2498 6.20983 24.4797 6.74146 24.4988 7.36424C24.518 7.98702 24.3073 8.51826 23.8668 8.95797L22.2294 10.5954ZM20.5633 12.2902L8.38344 24.4701H3.5V19.5866L15.6799 7.40675L20.5633 12.2902ZM16.5129 11.4571L15.7086 10.6528L17.3173 12.2615L16.5129 11.4571Z' />
                      </svg>
                    </span>
                    <span>Edit</span>
                  </Button>
                </div>
              </div>

              <div className='block-content'>
                <div className='block-content-item'>
                  <div className='block-content-label'>Payment system</div>
                  <div className='block-content-text name'>{remitDetails.paymentSystem}</div>
                </div>

                <div className='block-content-item'>
                  <div className='block-content-label'>You send exactly</div>
                  <div className='block-content-text upper'>
                    <b>
                      {remitDetails.sendValue} {remitDetails.currency}
                    </b>
                  </div>
                </div>

                <div className='block-content-item'>
                  <div className='block-content-label'>Transaction fee</div>
                  <div className='block-content-text upper'>
                    {remitDetails.transactionFee} {remitDetails.transactionFeeCurrency}
                  </div>
                </div>

                {!remitDetails.totalMixed ? (
                  <div className='block-content-item'>
                    <div className='block-content-label'>Total</div>
                    <div className='block-content-text upper'>
                      <b>
                        {transformNumbers(remitDetails.total, 2)}{' '}
                        <span className='currency'> {remitDetails.transactionFeeCurrency}</span>
                      </b>
                    </div>
                  </div>
                ) : (
                  <div className='block-content-item'>
                    <div className='block-content-label'>Total</div>
                    <div className='block-content-text upper'>
                      <b>
                        {transformNumbers(remitDetails.sendValue, 2)} {remitDetails.currency} +{' '}
                        {transformNumbers(remitDetails.transactionFee, 3)}
                        <span className='currency'> {remitDetails.transactionFeeCurrency}</span>
                      </b>
                    </div>
                  </div>
                )}

                {remitDetails?.equivalent && (
                  <div className='block-content-item'>
                    <div className='block-content-label'>USD Equivalent</div>
                    <div className='block-content-text'>
                      <span>{parseFloat(remitDetails.equivalent).toFixed(3)}</span> USD
                    </div>
                  </div>
                )}

                {remitDetails?.comment && (
                  <div className='block-content-item'>
                    <div className='block-content-label'>Comment</div>
                    <div className='block-content-text'>{remitDetails.comment}</div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </>
      )}

      {agentToAgentData && (
        <>
          <div className='block'>
            <div className='block-wrap'>
              <div className='block-top'>
                <div className='block-title'>Receiver details:</div>

                <div className='block-edit'>
                  <Button className='el-button' onClick={() => changeStep(-2)} variant='contained'>
                    <span className='el-button-icon'>
                      <svg
                        width='100%'
                        height='100%'
                        viewBox='0 0 28 28'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path d='M5.79809 22.172H7.40675L17.3173 12.2615L15.7086 10.6528L5.79809 20.5633V22.172ZM22.2294 10.5954L17.346 5.76936L18.9547 4.1607C19.3951 3.72023 19.9363 3.5 20.5783 3.5C21.2202 3.5 21.761 3.72023 22.2007 4.1607L23.8094 5.76936C24.2498 6.20983 24.4797 6.74146 24.4988 7.36424C24.518 7.98702 24.3073 8.51826 23.8668 8.95797L22.2294 10.5954ZM20.5633 12.2902L8.38344 24.4701H3.5V19.5866L15.6799 7.40675L20.5633 12.2902ZM16.5129 11.4571L15.7086 10.6528L17.3173 12.2615L16.5129 11.4571Z' />
                      </svg>
                    </span>
                    <span>Edit</span>
                  </Button>
                </div>
              </div>

              <div className='block-content'>
                <div className='block-content-item'>
                  <div className='block-content-label'>Name</div>
                  <div className='block-content-text'>{userReceiver?.name}</div>
                </div>
              </div>
            </div>
          </div>

          <div className='block'>
            <div className='block-wrap'>
              <div className='block-top'>
                <div className='block-title'>Transfer details:</div>

                <div className='block-edit'>
                  <Button className='el-button' onClick={() => changeStep(-1)} variant='contained'>
                    <span className='el-button-icon'>
                      <svg
                        width='100%'
                        height='100%'
                        viewBox='0 0 28 28'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path d='M5.79809 22.172H7.40675L17.3173 12.2615L15.7086 10.6528L5.79809 20.5633V22.172ZM22.2294 10.5954L17.346 5.76936L18.9547 4.1607C19.3951 3.72023 19.9363 3.5 20.5783 3.5C21.2202 3.5 21.761 3.72023 22.2007 4.1607L23.8094 5.76936C24.2498 6.20983 24.4797 6.74146 24.4988 7.36424C24.518 7.98702 24.3073 8.51826 23.8668 8.95797L22.2294 10.5954ZM20.5633 12.2902L8.38344 24.4701H3.5V19.5866L15.6799 7.40675L20.5633 12.2902ZM16.5129 11.4571L15.7086 10.6528L17.3173 12.2615L16.5129 11.4571Z' />
                      </svg>
                    </span>
                    <span>Edit</span>
                  </Button>
                </div>
              </div>

              <div className='block-content'>
                <div className='block-content-item'>
                  <div className='block-content-label'>Payment system</div>
                  <div className='block-content-text name'>{userReview.paymentSystem}</div>
                </div>

                <div className='block-content-item'>
                  <div className='block-content-label'>You send exactly</div>
                  <div className='block-content-text'>
                    <b>
                      {userReview.sendValue} {userReview.currency.toUpperCase()}
                    </b>
                  </div>
                </div>

                <div className='block-content-item'>
                  <div className='block-content-label'>Transaction fee</div>
                  <div className='block-content-text'>
                    {userReview.transactionFee} {userReview.transactionFeeCurrency.toUpperCase()}
                  </div>
                </div>

                {!userReview.totalMixed ? (
                  <div className='block-content-item'>
                    <div className='block-content-label'>Total</div>
                    <div className='block-content-text upper'>
                      <b>
                        {transformNumbers(userReview.total, 2)}{' '}
                        <span className='currency'> {userReview.transactionFeeCurrency}</span>
                      </b>
                    </div>
                  </div>
                ) : (
                  <div className='block-content-item'>
                    <div className='block-content-label'>Total</div>
                    <div className='block-content-text upper'>
                      <b>
                        {transformNumbers(userReview.sendValue, 2)} {userReview.currency} +{' '}
                        {transformNumbers(userReview.transactionFee, 3)}
                        <span className='currency'> {userReview.transactionFeeCurrency}</span>
                      </b>
                    </div>
                  </div>
                )}

                {userReview?.equivalent && (
                  <div className='block-content-item'>
                    <div className='block-content-label'>USD Equivalent</div>
                    <div className='block-content-text'>
                      <span>{parseFloat(userReview.equivalent).toFixed(3)}</span> USD
                    </div>
                  </div>
                )}

                {userReview?.comment && (
                  <div className='block-content-item'>
                    <div className='block-content-label'>Comment</div>
                    <div className='block-content-text'>{userReview.comment}</div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </>
      )}

      {MPesaData && (
        <>
          <div className='block'>
            <div className='block-wrap'>
              <div className='block-top'>
                <div className='block-title'>Receiver details:</div>

                <div className='block-edit'>
                  <Button className='el-button' onClick={() => changeStep(-2)} variant='contained'>
                    <span className='el-button-icon'>
                      <svg
                        width='100%'
                        height='100%'
                        viewBox='0 0 28 28'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path d='M5.79809 22.172H7.40675L17.3173 12.2615L15.7086 10.6528L5.79809 20.5633V22.172ZM22.2294 10.5954L17.346 5.76936L18.9547 4.1607C19.3951 3.72023 19.9363 3.5 20.5783 3.5C21.2202 3.5 21.761 3.72023 22.2007 4.1607L23.8094 5.76936C24.2498 6.20983 24.4797 6.74146 24.4988 7.36424C24.518 7.98702 24.3073 8.51826 23.8668 8.95797L22.2294 10.5954ZM20.5633 12.2902L8.38344 24.4701H3.5V19.5866L15.6799 7.40675L20.5633 12.2902ZM16.5129 11.4571L15.7086 10.6528L17.3173 12.2615L16.5129 11.4571Z' />
                      </svg>
                    </span>
                    <span>Edit</span>
                  </Button>
                </div>
              </div>

              <div className='block-content'>
                <div className='block-content-item'>
                  <div className='block-content-label'>Phone number</div>
                  <div className='block-content-text'>{userReceiver?.phoneNumber}</div>
                </div>
              </div>
            </div>
          </div>

          <div className='block'>
            <div className='block-wrap'>
              <div className='block-top'>
                <div className='block-title'>Transfer details:</div>

                <div className='block-edit'>
                  <Button className='el-button' onClick={() => changeStep(-1)} variant='contained'>
                    <span className='el-button-icon'>
                      <svg
                        width='100%'
                        height='100%'
                        viewBox='0 0 28 28'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path d='M5.79809 22.172H7.40675L17.3173 12.2615L15.7086 10.6528L5.79809 20.5633V22.172ZM22.2294 10.5954L17.346 5.76936L18.9547 4.1607C19.3951 3.72023 19.9363 3.5 20.5783 3.5C21.2202 3.5 21.761 3.72023 22.2007 4.1607L23.8094 5.76936C24.2498 6.20983 24.4797 6.74146 24.4988 7.36424C24.518 7.98702 24.3073 8.51826 23.8668 8.95797L22.2294 10.5954ZM20.5633 12.2902L8.38344 24.4701H3.5V19.5866L15.6799 7.40675L20.5633 12.2902ZM16.5129 11.4571L15.7086 10.6528L17.3173 12.2615L16.5129 11.4571Z' />
                      </svg>
                    </span>
                    <span>Edit</span>
                  </Button>
                </div>
              </div>

              <div className='block-content'>
                <div className='block-content-item'>
                  <div className='block-content-label'>Payment system</div>
                  <div className='block-content-text name'>{userReview.paymentSystem}</div>
                </div>

                <div className='block-content-item'>
                  <div className='block-content-label'>You send exactly</div>
                  <div className='block-content-text'>
                    <b>{`${userReview.sendValue} ${userReview.currency.toUpperCase()}`}</b>
                  </div>
                </div>

                <div className='block-content-item'>
                  <div className='block-content-label'>Transaction fee</div>
                  <div className='block-content-text'>{`${userReview.transactionFee} ${userReview.transactionFeeCurrency.toUpperCase()}`}</div>
                </div>

                {!userReview.totalMixed ? (
                  <div className='block-content-item'>
                    <div className='block-content-label'>Total</div>
                    <div className='block-content-text upper'>
                      <b>
                        {transformNumbers(userReview.total, 2)}{' '}
                        <span className='currency'> {userReview.transactionFeeCurrency}</span>
                      </b>
                    </div>
                  </div>
                ) : (
                  <div className='block-content-item'>
                    <div className='block-content-label'>Total</div>
                    <div className='block-content-text upper'>
                      <b>
                        {transformNumbers(userReview.sendValue, 2)} {userReview.currency} +{' '}
                        {transformNumbers(userReview.transactionFee, 3)}
                        <span className='currency'> {userReview.transactionFeeCurrency}</span>
                      </b>
                    </div>
                  </div>
                )}

                {userReview?.equivalent && (
                  <div className='block-content-item'>
                    <div className='block-content-label'>USD Equivalent</div>
                    <div className='block-content-text'>
                      <span>{parseFloat(userReview.equivalent).toFixed(3)}</span> USD
                    </div>
                  </div>
                )}

                {userReview?.comment && (
                  <div className='block-content-item'>
                    <div className='block-content-label'>Comment</div>
                    <div className='block-content-text'>{userReview.comment}</div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

Review.propTypes = {
  changeStep: PropTypes.func.isRequired,
  transferData: PropTypes.object,
  userToken: PropTypes.string,
};
export default Review;
