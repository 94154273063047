import { io } from 'socket.io-client';

class SocketConnection {
  socketEndpointOrders = 'https://api.pesabase.com/orders';
  socketEndpointNoti = 'https://api.pesabase.com/notifications';

  // The constructor will initialize the Socket Connection
  constructor(jwt) {
    this.socketOrders = io(this.socketEndpointOrders, {
      autoConnect: false,
      extraHeaders: {
        token: `${jwt}`,
      },
      transports: ['polling', 'websocket'],
    });
    this.socketNotifications = io(this.socketEndpointNoti, {
      autoConnect: false,
      extraHeaders: {
        token: `${jwt}`,
      },
      transports: ['polling', 'websocket'],
    });
  }
}

let socketConnection;

// The SocketFactory is responsible for creating and returning a single instance of the SocketConnection class
// Implementing the singleton pattern
class SocketFactory {
  static create(jwt) {
    if (!socketConnection) {
      console.log('being callled');
      socketConnection = new SocketConnection(jwt);
    }
    return socketConnection;
  }
}

export default SocketFactory;
