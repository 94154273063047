import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Success from '../../../components/Success';
import LoadingButton from '@mui/lab/LoadingButton';
import { toast } from 'react-toastify';
import { getOTP } from '../../../api';
import { useDispatch, useSelector } from 'react-redux';
import { selectFirebaseJWT, setTransferData } from '../../../redux/features/user/userSlice';
import trustVideo from '../../../assets/anims/videos/fullscreen/device-warn-loop.mp4';
import useUserRole from '../../../utils/useUserRole';

const TrustDevice = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const firebaseJWT = useSelector(selectFirebaseJWT);
  const { state } = useLocation();
  const dispatch = useDispatch();
  const userRole = useUserRole();
  const isAgent = useUserRole('agent');

  const handleTrustDevice = async () => {
    try {
      setLoading(true);
      await getOTP(firebaseJWT);

      setTimeout(() => {
        setLoading(false);
        navigate('/auth/two-factor-auth', { state: { ...state, device: true } });
      }, 1000);
    } catch (error) {
      console.log('handleTrustDevice', error);
      toast.error(`Something went wrong...${error?.message || 'Oops!'}`);
      setLoading(false);
    }
  };

  const handleNotTrust = () => {
    if (state?.reg) {
      navigate('/auth/thanks/registration', { state: { registration: true } });
    } else if (state?.buyFlow) {
      navigate(`/${userRole}/dashboard`);
    } else {
      dispatch(setTransferData(null));
      navigate(`/${userRole}/dashboard`);
    }
  };

  return (
    <Success
      video={trustVideo}
      title={'Trust this device?'}
      subtitle={
        state?.flow || state?.buyFlow
          ? "We prioritize your security. To proceed with transactions safely, please trust this device – it's a crucial security layer."
          : 'This will speed up the process of sending transactions in the future'
      }
      buttons={
        <>
          <LoadingButton loading={loading} className='el-button orange' onClick={handleTrustDevice}>
            <span>{loading ? 'Processing' : 'Trust'}</span>
          </LoadingButton>

          {!isAgent && (
            <LoadingButton className='el-button' onClick={handleNotTrust}>
              {state?.flow || state?.buyFlow ? 'Cancel' : 'Don’t trust'}
            </LoadingButton>
          )}
        </>
      }
    />
  );
};

export default TrustDevice;
