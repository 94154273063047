import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Review from '../..//Review';
import ArrowBack from '../..//ArrowBack';
import { getOTP } from '../../../api';
import closeFlow from '../../../utils/closeFlow';
import { toast } from 'react-toastify';
import { LoadingButton } from '@mui/lab';
import Estimate from '../../Estimate';

const SendTCReview = ({ changeStep, transferData, userToken }) => {
  const [localLoading, setLocalLoading] = useState(false);

  const goToTheNextStep = async () => {
    try {
      setLocalLoading(true);
      await getOTP(userToken);
      changeStep(1);
    } catch (error) {
      console.log(error);
      toast.error(`Something went wrong...${error?.message || 'Oops!'}`);
    } finally {
      setLocalLoading(false);
    }
  };

  return (
    <div className='send-step'>
      <ArrowBack handle={() => changeStep(-1)} />

      <div className='payment-title'>
        <h6 className='el-title-h6 med'>Review details of your transfer</h6>
      </div>

      <div className='send-step-content'>
        <Review userToken={userToken} changeStep={changeStep} transferData={transferData} />

        <div className='send-step-content-rewards'>
          {transferData?.userReview?.transferRewardsInPesa && (
            <Estimate
              type={'large'}
              count={parseFloat(transferData?.userReview?.transferRewardsInPesa).toFixed(6)}
              text={'Reward for transaction'}
            />
          )}
        </div>

        <div className='send-btns'>
          <div className='send-btns-continue'>
            <LoadingButton
              loading={localLoading}
              onClick={goToTheNextStep}
              className='el-button orange'
            >
              <span>{localLoading ? 'Processing' : 'Continue'}</span>
            </LoadingButton>
          </div>
          <div className='send-btns-cancel'>
            <div className='send-btns-cancel-wrap'>
              <span className='el-link-m el-button-link' variant='contained' onClick={closeFlow}>
                Cancel
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

SendTCReview.propTypes = {
  changeStep: PropTypes.func.isRequired,
  transferData: PropTypes.object.isRequired,
  userToken: PropTypes.string.isRequired,
};
export default SendTCReview;
