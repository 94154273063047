import React from 'react';
import PropTypes from 'prop-types';
import TFA from '../../../../components/TFA';
import ArrowBack from '../../../../components/ArrowBack';
import { useSelector } from 'react-redux';
import { selectPhoneNumber } from '../../../../redux/features/user/userSlice';

const WithdrawOTP = ({ loading, changeStep, submitSend, flow, error, setError }) => {
  const userPhoneNumber = useSelector(selectPhoneNumber);

  return (
    <div className='send-step send-step-3'>
      <ArrowBack handle={() => changeStep(-1)} />

      <TFA
        loading={loading}
        number={userPhoneNumber}
        changeStep={changeStep}
        handle={submitSend}
        closeFlowFn={true}
        setError={setError}
        error={error}
        withdraw={true}
        confirmBtnText='Confirm withdrawal'
      />
    </div>
  );
};

WithdrawOTP.propTypes = {
  changeStep: PropTypes.func.isRequired,
  submitSend: PropTypes.func.isRequired,
};
export default WithdrawOTP;
