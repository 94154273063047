import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, TextField } from '@mui/material';
import { toast } from 'react-toastify';
import {
  getAgentOTP,
  getContactSend,
  getUserData,
  submitReceiver,
  submitSender,
} from '../../../api';
import { selectFirebaseJWT, selectJwtToken } from '../../../redux/features/user/userSlice';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectRemitDetails,
  selectRemitSender,
  setRemitReceiver,
  setRemitDetails,
  setRemitSender,
  selectRemitReceiver,
  resetSenderUser,
  resetReceiverUser,
} from '../../../redux/features/transaction/remittanceSlice';
import useDebounce from '../../../hooks/useDebounce';
import SingleContact from '../../../components/SingleContact';
import { v4 as uuidv4 } from 'uuid';
import closeFlow from '../../../utils/closeFlow';
import ArrowBack from '../../../components/ArrowBack';
import VerifyOption from '../../../components/VerifyOption';
import iconID from '../../../assets/icons/icon-id.png';
import iconCode from '../../../assets/icons/icon-code-key.png';
import TFA from '../../../components/TFA';
import NoResult from '../../../components/NoResult';
import NewUserFlow from './NewUser/NewUserFlow';
import PhoneField from '../../../components/PhoneField';

const RemitUser = ({
  step,
  changeStep,
  senderUser,
  newUserSteps,
  newUserFlow,
  changeNewUserFlow,
  changeMainStep,
}) => {
  const dispatch = useDispatch();
  const userToken = useSelector(selectFirebaseJWT);
  const userDeviceJwt = useSelector(selectJwtToken);
  const remitDetails = useSelector(selectRemitDetails);
  const currentUser = useSelector(senderUser ? selectRemitSender : selectRemitReceiver);
  // State to hold the list of users fetched.
  const [users, setUsers] = useState(null);
  // State to manage error presence.
  const [error, setError] = useState(false);
  // State to hold the secret code input by the user.
  const [secretCode, setSecretCode] = useState(remitDetails?.verificationCode || '');
  // State to hold the sender's phone number.
  const [currentUserPhone, setCurrentUserPhone] = useState('');

  const [ btnLoading, setBtnLoading ] = useState(false);

  const [verificationType, setVerificationType] = useState(remitDetails?.verificationType || '');
  // State to manage local loading indicator.
  const [localLoading, setLocalLoading] = useState(false);
  // State to hold the phone number being searched.
  const [searchPhone, setSearchPhone] = useState({
    phoneNumber: currentUser?.phoneNumber || '',
    dialCode: '',
    isValid: false,
  });

  const { phoneNumber, isValid, dialCode } = searchPhone;

  // Debounced search value to minimize API calls during search.
  const debouncedSearchValue = useDebounce(phoneNumber, 600);
  const userSteps = senderUser ? 'senderSteps' : 'receiverSteps';
  /**
   * Handles the search for a user based on the phone number.
   */
  const handleSearchUser = async () => {
    try {
      setLocalLoading(true);
      const data = await getContactSend(userToken, phoneNumber);
      const objectKey = Object.keys(data.contact);
      const getArray = data?.contact[objectKey[0]];

      if (data && getArray && objectKey && Array.isArray(getArray)) {
        setUsers(getArray);
      } else {
        setUsers(null);
      }
    } catch (error) {
      toast.error(`Something went wrong...${error?.message || 'Oops!'}`);
    } finally {
      setLocalLoading(false);
    }
  };

  /**
   * Sets the selected user as either sender or receiver based on the flow.
   * @param {Object} contact - The contact object of the selected user.
   */

  const setUser = async (contact) => {
    try {
      setBtnLoading(contact.uid);
      setCurrentUserPhone(contact.phoneNumber);

      const setFunction = senderUser ? setRemitSender : setRemitReceiver;
      const resetUser = senderUser ? resetSenderUser : resetReceiverUser;
      dispatch(setFunction({ phoneNumber: contact.phoneNumber }));

      const response = await getUserData(userToken, contact.uid);
      const userData = response?.data;

      if (response.status !== 200) {
        throw new Error(response.message);
      }

      if (contact?.kycState.verificationState === 'verified') {
        dispatch(setFunction({ ...contact, ...userData }));
        dispatch(setRemitDetails({ newUserFlow: false }));
        
        if (senderUser) {
          await getAgentOTP(userToken, contact.phoneNumber);
          changeStep(userSteps, 1);
        } else {
          submitUser(false, { ...contact, ...userData });
        }
        setBtnLoading(false);
      } else {
        dispatch(resetUser());
        dispatch(setFunction({ ...contact, ...userData }));
        dispatch(setRemitDetails({ newUserFlow: true }));
        changeNewUserFlow(true);
        setBtnLoading(false);
      }
    } catch (error) {
      toast.error(`Something went wrong...${error?.message || 'Oops!'}`);
      console.error('setUser error:', error);
      setError(true);
      setBtnLoading(false);
    }
  };

  /**
   * Updates the remittance details with the verification code entered by the user.
   * @param {Object} e - The event object from the input field.
   */
  const setCode = () => {
    dispatch(setRemitDetails({ verificationCode: secretCode, verificationType: verificationType }));
    changeMainStep(1);
  };

  /**
   * Updates the remittance details with the selected verification type.
   * @param {string} type - The verification type selected by the user.
   */
  const setVerification = (type) => {
    setVerificationType(type);
    changeStep(userSteps, 1);
  };

  /**
   * Submits the sender user details after OTP verification.
   * @param {string} otpCode - The OTP code entered by the user.
   */
  const submitUser = async (otpCode, contact) => {
    try {
      setLocalLoading(true);
      const contactData = contact || currentUser;
      console.log(contactData);
      const data = senderUser
        ? {
            code: otpCode,
            phoneNumber: currentUser.phoneNumber,
            personalInfo: {
              title: currentUser.personalInfo?.title,
              firstName: currentUser.personalInfo?.firstName,
              lastName: currentUser.personalInfo?.lastName,
              middleName: currentUser.personalInfo?.middleName,
              gender: currentUser.personalInfo?.gender,
              dateOfBirth: currentUser.personalInfo?.dateOfBirth,
              email: currentUser.personalInfo?.email,
              occupation: currentUser.personalInfo?.occupation,
            },
            location: {
              suburb: currentUser.location?.suburb,
              city: currentUser.location?.suburb,
              country: currentUser.location?.country,
            },
            document: {
              type: currentUser.documents?.type,
              front: currentUser.documents?.front,
              back: currentUser.documents?.back,
              expirationDate: currentUser.documents?.expirationDate,
            },
            createAccount: currentUser.personalInfo?.createAccount,
          }
        : {
            phoneNumber: contactData.phoneNumber,
            personalInfo: {
              title: contactData.personalInfo?.title,
              firstName: contactData.personalInfo?.firstName,
              lastName: contactData.personalInfo?.lastName,
              middleName: contactData.personalInfo?.middleName,
              gender: contactData.personalInfo?.gender,
              dateOfBirth: contactData.personalInfo?.dateOfBirth,
              email: contactData.personalInfo?.email,
              occupation: contactData.personalInfo?.occupation,
            },
          };

      const submitFunction = senderUser ? submitSender : submitReceiver;
      const response = await submitFunction(userToken, userDeviceJwt, remitDetails.remitID, data);

      if (response.status === 200) {
        if (senderUser) {
          changeMainStep(1);
        } else {
          changeStep('receiverSteps', 2);
        }
        changeNewUserFlow(false);
      } else {
        throw new Error(response.message);
      }
    } catch (error) {
      toast.error(`Something went wrong...${error?.message || 'Oops!'}`);
      console.log(error);
      setError(true);
    } finally {
      setLocalLoading(false);
    }
  };

  // Renders a skeleton loader during data fetching.
  const skeleton = (
    <div className='c-contact-box-el flow'>
      <div className='c-contact-box-el-symbol'></div>
      {[...new Array(5)].map(() => (
        <div className='contact-wrap' key={uuidv4()}>
          <SingleContact loading={true} />
        </div>
      ))}
    </div>
  );

  const isCountryCodeSetted = debouncedSearchValue.length > dialCode.length;

  const handleSetNumber = ({ phoneNumber, isValid, dialCode }) =>
    setSearchPhone((prevState) => {
      return {
        ...prevState,
        phoneNumber,
        isValid,
        dialCode,
      };
    });

  const submitFnForBtn = () => {
    const setFunction = senderUser ? setRemitSender : setRemitReceiver;
    dispatch(setFunction({ phoneNumber: phoneNumber }));
    changeNewUserFlow(true);
  };

  // Handles user search operation based on the debounced search value.
  useEffect(() => {
    if (debouncedSearchValue && debouncedSearchValue !== '+' && isCountryCodeSetted) {
      handleSearchUser();
    }

    if (!isCountryCodeSetted) setUsers(null);
  }, [debouncedSearchValue]);
  return (
    <>
      {/* if user registered and passed KYC */}
      {!newUserFlow && (
        <>
          {step === 0 && (
            <div className='send-step'>
              <ArrowBack handle={() => changeStep(userSteps, -1)} />

              <div className='payment-title'>
                <h6 className='el-title-h6 med'>
                  {senderUser ? (
                    <>
                      Enter the <span className='orange'>Sender’s</span> phone number
                    </>
                  ) : (
                    <>
                      Enter the <span className='orange'>Receiver’s</span> phone number
                    </>
                  )}
                </h6>
              </div>

              <div className='send-step-content'>
                <div className='sender-phone'>
                  <PhoneField searchPhone={phoneNumber} setSearchPhone={handleSetNumber} />
                </div>

                {users && phoneNumber && !localLoading && (
                  <div className='c-contact-box-el remit'>
                    <div className='el-text-m med'>Search results:</div>

                    {users.map((user) => (
                      <div className='contact-wrap' key={uuidv4()}>
                        <SingleContact
                          contact={user}
                          setData={setUser}
                          flow={true}
                          agentFlow={true}
                          btnLoading={btnLoading}
                        />
                      </div>
                    ))}
                  </div>
                )}

                {!users && phoneNumber && !localLoading && isCountryCodeSetted && (
                  <div className='c-contact-box-el'>
                    <div className='el-text-m med'>Search results:</div>

                    <NoResult>
                      {
                        'No users found. Check the phone number is correct or send to unregistered user'
                      }
                    </NoResult>
                  </div>
                )}

                {localLoading && skeleton}

                {!users && !localLoading && isCountryCodeSetted && (
                  <div className='send-btns'>
                    <div className='send-btns-continue'>
                      <Button
                        disabled={!isValid}
                        className='el-button orange'
                        variant='contained'
                        onClick={submitFnForBtn}
                      >
                        Continue
                      </Button>
                    </div>

                    <div className='send-btns-cancel'>
                      <span
                        className='el-link-m el-button-link'
                        variant='contained'
                        onClick={closeFlow}
                      >
                        Cancel
                      </span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}

          {step === 1 && (
            <div className='send-step'>
              <ArrowBack handle={() => changeStep('senderSteps', -1)} />

              <TFA
                loading={localLoading}
                number={currentUserPhone}
                changeStep={changeStep}
                handle={submitUser}
                resendAgentFn={true}
                setError={setError}
                error={error}
                closeFlowFn={true}
              />
            </div>
          )}

          {step === 2 && (
            <div className='send-step'>
              <ArrowBack handle={() => changeStep(userSteps, senderUser ? -1 : -2)} />

              <div className='payment-title'>
                <h6 className='el-title-h6 med'>Verify option</h6>

                <span className='el-text-m payment-title--subtitle'>
                  Choose the option how receiver will be verified
                </span>
              </div>

              <div className='send-step-content'>
                <div className='c-option-verify'>
                  <div onClick={() => setVerification('id-number')}>
                    <VerifyOption
                      image={iconID}
                      title={'ID number'}
                      subtitle={'Receiver will be asked for their ID number to receive money'}
                    />
                  </div>

                  <div onClick={() => setVerification('secret-code')}>
                    <VerifyOption
                      image={iconCode}
                      title={'Secret code'}
                      subtitle={'Receiver will be asked for a secret code to receive money'}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}

          {step === 3 && (
            <div className='send-step'>
              <ArrowBack handle={() => changeStep(userSteps, -1)} />

              <div className='payment-title'>
                {verificationType === 'id-number' ? (
                  <>
                    <h6 className='el-title-h6 med'>Enter Receiver’s ID number</h6>

                    <span className='el-text-m payment-title--subtitle'>
                      Receiver will be asked for this ID number to receive money
                    </span>
                  </>
                ) : (
                  <>
                    <h6 className='el-title-h6 med'>Enter secret code</h6>

                    <span className='el-text-m payment-title--subtitle'>
                      Receiver will be asked for this secret code to receive money
                    </span>
                  </>
                )}
              </div>

              <div className='send-step-content'>
                <TextField
                  className='el-input'
                  label={`${verificationType === 'id-number' ? 'ID number' : 'Secret code'}`}
                  type='text'
                  name='code'
                  variant='outlined'
                  onChange={(e) => setSecretCode(e.target.value)}
                  value={secretCode}
                />
              </div>

              <div className='send-btns'>
                <div className='send-btns-continue'>
                  <Button
                    disabled={!secretCode}
                    className='el-button orange'
                    variant='contained'
                    onClick={setCode}
                  >
                    Continue
                  </Button>
                </div>

                <div className='send-btns-cancel'>
                  <span
                    className='el-link-m el-button-link'
                    variant='contained'
                    onClick={closeFlow}
                  >
                    Cancel
                  </span>
                </div>
              </div>
            </div>
          )}
        </>
      )}
      {/* if user not registered */}
      {newUserFlow && (
        <NewUserFlow
          sender={senderUser}
          step={newUserSteps}
          changeStep={changeStep}
          localLoading={localLoading}
          submitUser={submitUser}
          setError={setError}
          error={error}
        />
      )}
    </>
  );
};

RemitUser.propTypes = {
  changeStep: PropTypes.func.isRequired,
};

export default RemitUser;
