import React from 'react';
import useBodyClass from '../../hooks/useBodyClass';
import { Container } from '@mui/material';
import asideImg from '../../assets/img/p-privacy/aside.png';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';

const PostPage = () => {
  useBodyClass('p-post');

  return (
    <>
      <Header />
      <section className='s-post'>
        <Container disableGutters>
          <div className='s-post-wrap'>
            <aside className='s-post-aside'>
              <div className='s-post-aside-top-image'>
                <img src={asideImg} alt='' />
              </div>
            </aside>

            <article className='s-post-article'>
              <h3>TERMS AND CONDITIONS</h3>

              <span className='s-post-article--date'>Last updated: 2023-01-21</span>

              <h5>1. Introduction</h5>

              <p>
                Welcome to <b>Betzone</b> (“the Company”, “we”, “our”, “us”)!
              </p>

              <p>
                These Terms of Service (“Terms”, “Terms of Service”) govern your use of our website
                located at <b>betzone.co</b> (together or individually “Service”) operated by
                <b>Betzone.</b>
              </p>

              <p>
                Our Privacy Policy also governs your use of our Service and explains how we collect,
                safeguard and disclose information that results from your use of our web pages.
              </p>

              <p>
                Your agreement with us includes these Terms and our Privacy Policy (“Agreements”).
                You acknowledge that you have read and understood Agreements, and agree to be bound
                of them.
              </p>

              <p>
                If you do not agree with (or cannot comply with) Agreements, then you may not use
                the Service. These Terms apply to all visitors, users and others who wish to access
                or use Service.
              </p>

              <h5>2. Communications</h5>

              <p>
                By using our Service, you agree to subscribe to newsletters, marketing or
                promotional materials and other information we may send. However, you may opt out of
                receiving any, or all, of these communications from us by following the unsubscribe
                link or by emailing us.
              </p>

              <h5>3. Purchases</h5>

              <p>
                If you wish to purchase any product or service made available through Service
                (“Purchase”), you may be asked to supply certain information relevant to your
                Purchase including but not limited to, your credit or debit card number, the
                expiration date of your card, your billing address, and your shipping information.
              </p>

              <p>
                You represent and warrant that: (i) you have the legal right to use any card(s) or
                other payment method(s) in connection with any Purchase; and that (ii) the
                information you supply to us is true, correct and complete.
              </p>

              <p>
                We may employ the use of third party services for the purpose of facilitating
                payment and the completion of Purchases. By submitting your information, you grant
                us the right to provide the information to these third parties subject to our
                Privacy Policy.
              </p>

              <p>
                We reserve the right to refuse or cancel your order at any time for reasons
                including but not limited to: product or service availability, errors in the
                description or price of the product or service, error in your order or other
                reasons.
              </p>

              <p>
                We reserve the right to refuse or cancel your order if fraud or an unauthorized or
                illegal transaction is suspected.
              </p>
            </article>
          </div>
        </Container>
      </section>
      <Footer />
    </>
  );
};

export default PostPage;
