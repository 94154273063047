import React, { useContext, useState } from 'react';
import MenuItem from '@mui/material/MenuItem';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import closeFlow from '../../../utils/closeFlow';
import ArrowBack from '../../../components/ArrowBack';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import { ReactComponent as iconSelect } from '../../../assets/icons/icon-select.svg';
import UserContext from '../../../UserContext/UserContext';
import { LoadingButton } from '@mui/lab';
import {
  selectRemitDetails,
  setRemitDetails,
} from '../../../redux/features/transaction/remittanceSlice';
import { useEffect } from 'react';
import { createRemit, getAgentsCountries } from '../../../api';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { selectAccount, selectFirebaseJWT, selectJwtToken } from '../../../redux/features/user/userSlice';
import transformString from '../../../utils/transformString';
import useSWR from 'swr';

const RemitLocation = ({ changeStep }) => {
  const dispatch = useDispatch();
  const { setLoading } = useContext(UserContext);
  const [localLoading, setLocalLoading] = useState(false);
  const userToken = useSelector(selectFirebaseJWT);
  const deviceToken = useSelector(selectJwtToken);
  const remitDetails = useSelector(selectRemitDetails);
  const currentCountry = remitDetails?.pickUpLocation?.country;
  
  const [userLocation, setUserLocation] = useState({
    allLocations: [],
    country: transformString(currentCountry) || null,
    suburb: transformString(remitDetails?.pickUpLocation?.suburb) || null,
    defaultCountries: [],
    countryRegions: [],
  });

  const fetcher = (userToken) => getAgentsCountries(userToken).then((res) => res);
  const { data: response, error, isLoading } = useSWR(userToken, fetcher);
  
  if (error) {
    toast.error(`Something went wrong...${error?.message || 'Oops!'}`);
    console.log(error);
  }

  const { country, suburb, countryRegions, allLocations, defaultCountries } = userLocation;

  const getRegionsByCountry = (country) => {
    return allLocations
      .filter((item) => item.country.trim().toLowerCase() === transformString(country))
      .map((item) => item.city.trim().toLowerCase());
  };

  const handleCountryChange = (event) => {
    const regionsInCountry = getRegionsByCountry(event.target.value);
    setUserLocation({
      ...userLocation,
      country: event.target.value,
      countryRegions: regionsInCountry,
    });
  };

  const handleRegionChange = (event) =>
    setUserLocation({ ...userLocation, suburb: event.target.value });

  const submitData = async () => {
    try {
      setLocalLoading(true);
      const { allLocations, defaultCountries, countryRegions, ...data } = userLocation;
      const response = await createRemit(userToken, deviceToken, data);

      if (response.status === 200) {
        dispatch(setRemitDetails({ remitID: response.data.id, pickUpLocation: data }));
        changeStep(1);
      }
    } catch (error) {
      toast.error(`Something went wrong...${error?.message || 'Oops!'}`);
    } finally {
      setLocalLoading(false);
    }
  };

  const getUniqueEl = (data) => Array.from(new Set(data));

  useEffect(() => {
    setLoading(isLoading);
  }, [isLoading]);

  useEffect(() => {
    if (response?.status === 200) {
      const neededCountries = response.data.map((country) => country.country.toLowerCase());
      const neededRegions = response.data.map((_) => _.city.toLowerCase());
      const uniqueCountries = getUniqueEl(neededCountries);
      const uniqueRegions = getUniqueEl(neededRegions);

      setUserLocation((prevState) => ({
        ...prevState,
        allLocations: response.data,
        defaultCountries: uniqueCountries,
        countryRegions: uniqueRegions,
      }));
    }
  }, [response]);

  return (
    defaultCountries &&
    !isLoading && (
      <div className='send-step'>
        <ArrowBack handle={() => changeStep(-1)} />

        <div className='payment-title'>
          <h6 className='el-title-h6 med'>
            Enter <span className='orange'>Receiver’s cash pickup</span> location
          </h6>
        </div>

        <div className='send-step-content'>
          <div className='location-select'>
            <FormControl className='el-input-select'>
              <InputLabel id='select-label-1'>Select country</InputLabel>

              <Select
                labelId='select-label-1'
                id='select'
                value={country || ''}
                label='Select country'
                onChange={handleCountryChange}
                IconComponent={iconSelect}
              >
                {defaultCountries.map((country) => (
                  <MenuItem className='capitalize' key={uuidv4()} value={transformString(country)}>
                    {country}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl className='el-input-select'>
              <InputLabel id='select-label-2'>Select region</InputLabel>
              <Select
                disabled={!country}
                labelId='select-label-2'
                id='select-region'
                value={suburb || ''}
                label='Select region'
                onChange={handleRegionChange}
                IconComponent={iconSelect}
              >
                {countryRegions.map((region) => (
                  <MenuItem className='capitalize' key={uuidv4()} value={transformString(region)}>
                    {region}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <LoadingButton
              disabled={!suburb}
              loading={localLoading}
              onClick={submitData}
              className='el-button orange'
            >
              <span>{localLoading ? 'Processing' : 'Continue'}</span>
            </LoadingButton>

            <div className='send-btns-cancel'>
              <span className='el-link-m el-button-link' variant='contained' onClick={closeFlow}>
                Cancel
              </span>
            </div>
          </div>
        </div>
      </div>
    )
  );
};

RemitLocation.propTypes = {
  changeStep: PropTypes.func.isRequired,
};

export default RemitLocation;
