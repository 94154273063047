import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import usePictures from '../../hooks/usePictures';
import UserContext from '../../UserContext/UserContext';
import ReactPlayer from 'react-player';

const Success = ({ image, title, subtitle, subtitlebot, buttons, error, video }) => {
  const { loading } = useContext(UserContext);
  const icons = usePictures(
    require.context('../../assets/icons', false, /\.(png|jpe?g|svg|webp)$/)
  );

  return (
    <div className='success-box'>
      <div className='success-box-img'>
        {/* {image && <img src={image} alt='' />} */}

        {video && (
          <ReactPlayer
            className='player'
            controls={false}
            playing={true}
            loop={true}
            muted={true}
            playsinline={true}
            url={video}
            width='100%'
            height='100%'
          />
        )}
      </div>

      {!loading && (
        <>
          {title && <h4>{title}</h4>}

          {error ? (
            subtitle && (
              <div className='success-box-text'>
                <div className='success-box-text-wrap el-text-s'>
                  <img src={icons['icon-notification-red.svg']} a lt='' />
                  {subtitle}
                </div>
              </div>
            )
          ) : (
            <>
              {subtitle && (
                <div
                  className='success-box-text el-text-m'
                  dangerouslySetInnerHTML={{ __html: subtitle }}
                ></div>
              )}

              {subtitlebot && (
                <div
                  className='success-box-text el-text-m'
                  dangerouslySetInnerHTML={{ __html: subtitlebot }}
                ></div>
              )}
            </>
          )}
          {buttons && <div className='success-box-btns'>{buttons}</div>}
        </>
      )}
    </div>
  );
};

Success.propTypes = {
  image: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  buttons: PropTypes.object,
  error: PropTypes.bool,
  changeStep: PropTypes.func,
};

export default Success;
