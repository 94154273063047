import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Review from '../../../components/Review';
import { useSelector } from 'react-redux';
import ArrowBack from '../../../components/ArrowBack';
import closeFlow from '../../../utils/closeFlow';
import { getAgentOTP, getOTP } from '../../../api';
import { selectFirebaseJWT, selectPhoneNumber } from '../../../redux/features/user/userSlice';
import { toast } from 'react-toastify';
import { LoadingButton } from '@mui/lab';
import Estimate from '../../Estimate';
import useUserRole from '../../../utils/useUserRole';

const MPesaReview = ({ changeStep, flowData }) => {
  const userToken = useSelector(selectFirebaseJWT);
  const userPhone = useSelector(selectPhoneNumber);
  const [localLoading, setLocalLoading] = useState();
  const isAgent = useUserRole('agent');

  const getOTPcode = async () => {
    try {
      setLocalLoading(true);
      await (isAgent ? getAgentOTP(userToken, userPhone) : getOTP(userToken));
      changeStep(1);
    } catch (error) {
      toast.error(`Something went wrong...${error?.message || 'Oops!'}`);
      console.log(error);
    } finally {
      setLocalLoading(false);
    }
  };

  return (
    <div className='send-step'>
      <ArrowBack handle={() => changeStep(-1)} />

      <div className='payment-title'>
        <h6 className='el-title-h6 med'>Review details of your transfer</h6>
      </div>

      <div className='send-step-content'>
        <Review changeStep={changeStep} MPesaData={flowData} />

        <div className='send-step-content-rewards'>
          {flowData?.userReview?.transferRewardsInPesa && (
            <Estimate
              type={'large'}
              count={parseFloat(flowData?.userReview?.transferRewardsInPesa).toFixed(6)}
              text={'Reward for transaction'}
            />
          )}
        </div>

        <div className='send-btns'>
          <div className='send-btns-continue'>
            <LoadingButton
              disabled={localLoading}
              loading={localLoading}
              onClick={getOTPcode}
              className='el-button orange'
            >
              <span>{localLoading ? 'Processing' : 'Continue'}</span>
            </LoadingButton>
          </div>

          <div className='send-btns-cancel'>
            <span className='el-link-m el-button-link' variant='contained' onClick={closeFlow}>
              Cancel
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

MPesaReview.propTypes = {
  changeStep: PropTypes.func.isRequired,
};
export default MPesaReview;
