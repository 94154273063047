import { store } from './store';
import SocketFactory from '../socketFactory';
import {
  connectionEstablished,
  update_incomingOrders,
  update_outgoingOrders,
  update_poolOrders,
  update_notifications,
  update_messages,
  connectionLost,
} from './features/sockets/socketslice';
import { updateOrder } from './features/transaction/remittanceSlice';

let socket;

const EVENT_POOL_ORDERS = 'pool orders';
const EVENT_INCOMING_ORDERS = 'incoming orders';
const EVENT_OUTGOING_ORDERS = 'outgoing orders';
const EVENT_NOTIFICATIONS = 'notifications';

export const initializeSocketConnection = () => {
  return (dispatch, getState) => {
    const jwt = getState().user?.firebaseJWt;
    const order = getState().remittance.order;

    if (!socket) {
      socket = SocketFactory.create(jwt);
      socket.socketOrders.connect();
      socket.socketNotifications.connect();

      socket.socketOrders.on('connect', () => {
        console.log('connected to server');
        dispatch(connectionEstablished());
      });

      socket.socketOrders.on('connect_error', (error) => {
        console.log(error);
        dispatch(connectionLost());
      });

      socket.socketOrders.on(EVENT_POOL_ORDERS, (data) => {
        dispatch(update_poolOrders(data));
      });

      socket.socketOrders.on(EVENT_INCOMING_ORDERS, (data) => {
        dispatch(update_incomingOrders(data));
      });

      socket.socketOrders.on(EVENT_OUTGOING_ORDERS, (data) => {
        dispatch(update_outgoingOrders(data));
      });

      socket.socketOrders.on(`message-${order?.txId}`, (data) => {
        console.log('got messages', data);
        dispatch(update_messages({ txId: order?.txId, data: data }));
      });

      socket.socketOrders.on(`${order?.txId}`, (data) => {
        dispatch(updateOrder(data[0]));
      });

      socket.socketNotifications.on(EVENT_NOTIFICATIONS, (page, limit, length, number, data) => {
        dispatch(
          update_notifications({
            page: page,
            limit: limit,
            totalItems: length,
            number: number,
            data: data,
          })
        );
      });
    }

    store.subscribe(() => {
      const state = store.getState();
      const newOrder = state.remittance.order;
      const newJwt = state.user.firebaseJWt;

      if (newOrder && newOrder.txId !== order?.txId) {
        console.log('order changes');
        socket.socketOrders.off(`message-${order?.txId}`);
        socket.socketOrders.on(`message-${newOrder.txId}`, (data) => {
          console.log('got messages', data);
          dispatch(update_messages({ txId: newOrder.txId, data: data }));
        });

        socket.socketOrders.off(`${order?.txId}`);
        socket.socketOrders.on(`${newOrder.txId}`, (data) => {
          dispatch(updateOrder(data[0]));
        });
      }

      if (newJwt && newJwt !== jwt) {
        socket = SocketFactory.create(newJwt);
        socket.socketOrders.connect();
        socket.socketNotifications.connect();
      }
    });
  };
};
