import React, { useContext, useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import UserContext from '../../UserContext/UserContext';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import { Button } from '@mui/material';
import { ReactComponent as IconDashboard } from '../../assets/icons/icon-dashboard.svg';
import { ReactComponent as IconLogout } from '../../assets/icons/icon-acc-logout.svg';
import Popup from '../../components/Popup';
import logoutVideo from '../../assets/anims/videos/popups/logout_ff.mp4';
import { v4 as uuidv4 } from 'uuid';
import ReactPlayer from 'react-player';
import { useSelector } from 'react-redux';
import { selectAccount } from '../../redux/features/user/userSlice';
import useUserRole from '../../utils/useUserRole';

const Sidebar = ({ flowLinks, accountLinks, user, pesaLinks }) => {
  const role = useUserRole();
  const { logout } = useContext(UserContext);
  const [popUpOpen, setPopUpOpen] = useState(false);
  const togglePopUp = () => setPopUpOpen(!popUpOpen);
  const userAccount = useSelector(selectAccount);
  const dashboardLink = {
    link: `/${role}/dashboard`,
    label: 'Dashboard',
    icon: IconDashboard,
  };

  const logOut = () => {
    setPopUpOpen(true);
    logout();
  };

  return (
    <div className={`c-acc-sidebar ${userAccount?.canTransact ? '' : 'limited'}`}>
      <div className='c-acc-sidebar-box'>
        {user && (
          <Button
            disabled={!userAccount?.canTransact}
            component={Link}
            to={`/${role}/send/system`}
            variant='outlined'
            className='el-button orange account'
          >
            Send money
          </Button>
        )}

        <List>
          <ListItem>
            <NavLink to={dashboardLink.link}>
              <ListItemButton>
                <dashboardLink.icon />

                {dashboardLink.label}
              </ListItemButton>
            </NavLink>
          </ListItem>
        </List>

        {pesaLinks && (
          <List>
            {pesaLinks.map((item) => (
              <ListItem key={uuidv4()} disabled={item.disabled}>
                <NavLink to={item.link}>
                  <ListItemButton>
                    {item.icon && <item.icon />}
                    {item.label}
                  </ListItemButton>
                </NavLink>
              </ListItem>
            ))}
          </List>
        )}

        <List>
          {flowLinks.map((item) => (
            <ListItem key={uuidv4()} disabled={item.disabled}>
              <NavLink to={item.link}>
                <ListItemButton>
                  {item.icon && <item.icon />}
                  {item.label}
                </ListItemButton>
              </NavLink>
            </ListItem>
          ))}
        </List>

        <List>
          {accountLinks.map((item) => (
            <ListItem key={uuidv4()} disabled={item.disabled}>
              <NavLink to={item.link}>
                <ListItemButton>
                  {item.icon && <item.icon />}
                  {item.label}
                </ListItemButton>
              </NavLink>
            </ListItem>
          ))}
        </List>

        <List>
          <ListItem>
            <Button className='icon-logout' onClick={togglePopUp}>
              <IconLogout />
              Logout
            </Button>
          </ListItem>
        </List>
      </div>

      <Popup open={popUpOpen} togglePopUp={togglePopUp}>
        <div className='modal-content'>
          <div className='modal-img'>
            <ReactPlayer
              className='player'
              controls={false}
              playing={true}
              loop={true}
              muted={true}
              playsinline={true}
              url={logoutVideo}
              width='100%'
              height='100%'
            />
          </div>

          <div className='modal-desc'>
            <div className='modal-desc-title'>Are you sure want to log out?</div>
          </div>

          <div className='modal-btns'>
            <Button className='el-button el-button-btn' variant='contained' onClick={logOut}>
              Yes, I want to log out
            </Button>

            <Button
              className='el-button orange el-button-continue'
              variant='contained'
              onClick={togglePopUp}
            >
              No, I want to stay
            </Button>
          </div>
        </div>
      </Popup>
    </div>
  );
};

export default Sidebar;
